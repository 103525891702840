<template>
  <div class="pa-3">
    <v-tabs>
      <v-tabs-slider color="cyan"></v-tabs-slider>
      <v-tab> Premiação </v-tab>
      <v-tab> Resumo </v-tab>
      <!-- PREMIAÇÃO -->
      <v-tab-item
        ><v-container fluid
          ><div class="d-flex justify-center align-center">
            <v-row no-gutters>
              <v-col cols="1" class="ma-2">
                <v-select
                  class="rounded-lg"
                  v-model="anoEscolhido"
                  :loading="loadingAnos"
                  :items="anos"
                  label="Escolha o ano"
                  @change="buscaPessoas"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="2" class="ma-2">
                <v-select
                  class="rounded-lg"
                  v-model="trimestre"
                  :items="[
                    { text: '1 trimestre', value: '0101-0331' },
                    { text: '2 trimestre', value: '0401-0630' },
                    { text: '3 trimestre', value: '0701-0930' },
                    { text: '4 trimestre', value: '1001-1231' },
                  ]"
                  label="Escolha o Trimestre"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="5" class="ma-2">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="vendedor"
                  item-text="text"
                  item-value="value"
                  :items="vendedores"
                  label="Escolha o vendedor"
                  @change="setVendedor"
                  :disabled="vendedores.length <= 0"
                  v-on:keyup.enter="calcula"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="3"
                class="d-flex justify-center align-center ml-2"
                style="margin-top: -25px"
              >
                <v-switch
                  class="mx-2"
                  v-model="switchDetalhe"
                  :label="`Com detalhamento`"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  v-show="showDados"
                  x-small
                  dark
                  :disabled="showSkelekton"
                  class="mx-2 green darken-2"
                  @click="
                    () => {
                      dialogRefinamento = true;
                    }
                  "
                  >Refinamento
                </v-btn>
                <v-btn
                  x-small
                  :disabled="showSkelekton"
                  class="mx-2 primary"
                  @click="calcula"
                  >Calcular
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div v-show="showSkelekton">
            <v-skeleton-loader
              class="mx-auto"
              type="card, article, actions"
            ></v-skeleton-loader>
          </div>
          <div v-show="showDados">
            <div class="d-flex flex-column justify-center align-end mr-5">
              <span class="subtitle"
                >Prêmio Individual:
                {{ toCurrency(totalPremioIndividual) }}
              </span>
              <span class="subtitle"
                >Prêmio Opgrade: {{ toCurrency(totalPremioOpGrade) }}
              </span>
              <span class="subtitle"
                >Prêmio Equipe: {{ toCurrency(totalPremioEquipe) }}
              </span>
              <span v-show="totalRefinamento !== 0" class="subtitle"
                >Refinamento:
                <span
                  :class="
                    totalRefinamento < 0
                      ? 'red--text darken-3'
                      : 'green--text darken-3'
                  "
                  >{{ toCurrency(totalRefinamento) }}</span
                >
              </span>
            </div>
            <div class="d-flex flex-row justify-end align-center mr-5 mt-4">
              <span class="title"
                >Total de Prêmio:
                {{
                  totalPremioGeral.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}</span
              >
            </div>
            <div class="d-flex justify-center align-center pa-3">
              <v-expansion-panels>
                <v-expansion-panel width="95vw" v-for="(item, i) in 2" :key="i">
                  <v-expansion-panel-header v-if="i <= 0">
                    <v-row
                      dense
                      class="d-flex justify-space-between align-center"
                    >
                      <v-col
                        cols="2"
                        style="
                          font-weight: bold;
                          font-size: 1.2rem;
                          color: #1a237e;
                        "
                        >Detalhamento</v-col
                      >
                      <v-col cols="2"
                        >Número de linhas: {{ numberLines }}</v-col
                      >
                      <v-col cols="2"
                        >Valor Total: {{ valorTotalDetalhamento }}</v-col
                      >
                    </v-row>
                  </v-expansion-panel-header>

                  <v-expansion-panel-header v-else>
                    <template v-slot="{ open }">
                      <div class="d-flex justify-space-between align-center">
                        <span class="amber--text text--darken-3 title"
                          >Cálculo do Prêmio</span
                        >
                        <div>
                          <v-tooltip v-if="open" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                large
                                color="blue darken-2"
                                class="mr-5 mt-5"
                                v-bind="attrs"
                                v-on="on"
                                @click="handleGerarPdf"
                              >
                                <v-icon>mdi-file-pdf-box</v-icon>
                              </v-btn>
                            </template>
                            <span>Gerar PDF</span>
                          </v-tooltip>
                          <v-tooltip v-if="open" key="0" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                large
                                color="red darken-2"
                                class="mr-5 mt-5"
                                v-bind="attrs"
                                v-on="on"
                                @click="chamaDialogEmail"
                              >
                                <v-icon>mdi-email-fast</v-icon>
                              </v-btn>
                            </template>
                            <span>Enviar e-mail de premiação com o PDF.</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content v-if="i <= 0">
                    <v-card class="pa-2">
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="3">
                          <v-text-field
                            v-model="searchDetalhe"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-tooltip bottom>
                          <v-spacer></v-spacer>
                          <template v-slot:activator="{ on, attrs }">
                            <vue-excel-xlsx
                              :data="itemsRelatExcel"
                              :columns="headersRelatExcel"
                              :filename="nameRelatExcel"
                              :sheetname="`Vendas`"
                              class="ml-5 mb-5 tbExcel"
                            >
                              <v-btn
                                icon
                                color="blue darken-2"
                                class="mr-5 mt-5"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-microsoft-excel</v-icon>
                              </v-btn>
                            </vue-excel-xlsx>
                          </template>
                          <span>Exportar para excel</span>
                        </v-tooltip>
                      </v-row>
                      <v-data-table
                        :items="itemsDetalhamento"
                        :headers="headersDetalhamento"
                        :search="searchDetalhe"
                        width="100vw"
                        height="40vh"
                        hide-default-footer
                        fixed-header
                        dense
                        :items-per-page="
                          itemsDetalhamento ? itemsDetalhamento.length : 0
                        "
                      ></v-data-table>
                    </v-card>
                  </v-expansion-panel-content>

                  <v-expansion-panel-content v-else>
                    <!--Div para premiação Prospector Indicação-->
                    <div class="pa-2" v-if="prospeccaoIndicacao">
                      <v-card>
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >{{ labelBanner1 }} - Meta:
                          {{ metaIndividualIndicacao }} - Prêmio Indicação
                          Médica
                        </v-card-title>
                      </v-card>
                      <v-banner
                        v-if="['000009', '000014'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - Indivídual - Meta
                        {{ metaIndividualIndicacao }}</v-banner
                      >
                      <v-banner
                        v-if="['000025'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - EQUIPES - Meta
                        {{ metaIndividualIndicacao }}</v-banner
                      >
                      <div class="pa-2">
                        <v-card
                          class="d-flex"
                          color="grey lighten-2"
                          flat
                          height="100"
                          tile
                        >
                          <v-card
                            class="d-flex align-center justify-center"
                            width="25vw"
                            outlined
                            tile
                            color="orange"
                            style="font-size: 0.9rem; font-weight: bold"
                          >
                            PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                            META DE FATURAMENTO)
                          </v-card>

                          <div class="d-flex flex-column">
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                            >
                              <v-row style="margin-left: 0px">
                                <v-col
                                  cols="6"
                                  style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                >
                                  Até
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="background-color: #ff8a65"
                                >
                                  {{ metaAteIndividualIndicacao }}
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                              style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                            >
                              NÃO HÁ PRÊMIAÇÃO
                            </v-card>
                          </div>

                          <v-card
                            class="d-flex align-center justify-center"
                            width="7vw"
                            outlined
                            color="orange"
                            tile
                          >
                            Base de Cálculo FL
                          </v-card>
                        </v-card>
                      </div>
                      <v-data-table
                        class="elevation-1 tbResults"
                        :headers="[
                          {
                            text: 'Valor alcançado',
                            align: 'start',
                            value: 'vl_vendido',
                            class: 'black--text title',
                          },
                          {
                            text: 'Faixa Inicial',
                            align: 'start',
                            value: 'fx_inicial',
                          },
                          {
                            text: 'Faixa Final',
                            align: 'start',
                            value: 'fx_final',
                          },
                          {
                            text: 'Porcentagem aplicada',
                            align: 'start',
                            value: 'porcentagem_aplicada',
                          },
                          {
                            text: 'Valor inicial',
                            align: 'start',
                            value: 'vl_inicial',
                          },
                          {
                            text: 'Valor final',
                            align: 'start',
                            value: 'vl_final',
                          },
                          {
                            text: 'Prêmio',
                            align: 'start',
                            value: 'vl_premio',
                            class: 'success--text title',
                          },
                        ]"
                        :items="calculoDadosComissaoIndicacao"
                        hide-default-footer
                      ></v-data-table>
                    </div>

                    <!--Div para premiação Prospector Visitação-->
                    <div class="pa-2" v-if="prospeccaoVisitacao">
                      <v-card>
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >{{ labelBanner1 }} - Meta:
                          {{ metaIndividualVisitacao }} - Prêmio Visitação
                          Médica
                        </v-card-title>
                      </v-card>
                      <v-banner
                        v-if="['000009', '000014'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - Indivídual - Meta
                        {{ metaIndividualVisitacao }}</v-banner
                      >
                      <v-banner
                        v-if="['000025'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - EQUIPES - Meta
                        {{ metaIndividualVisitacao }}</v-banner
                      >
                      <div class="pa-2">
                        <v-card
                          class="d-flex"
                          color="grey lighten-2"
                          flat
                          height="100"
                          tile
                        >
                          <v-card
                            class="d-flex align-center justify-center"
                            width="25vw"
                            outlined
                            tile
                            color="orange"
                            style="font-size: 0.9rem; font-weight: bold"
                          >
                            PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                            META DE FATURAMENTO)
                          </v-card>

                          <div class="d-flex flex-column">
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                            >
                              <v-row style="margin-left: 0px">
                                <v-col
                                  cols="6"
                                  style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                >
                                  Até
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="background-color: #ff8a65"
                                >
                                  {{ metaAteIndividualVisitacao }}
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                              style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                            >
                              NÃO HÁ PRÊMIAÇÃO
                            </v-card>
                          </div>

                          <v-card
                            class="d-flex align-center justify-center"
                            width="7vw"
                            outlined
                            color="orange"
                            tile
                          >
                            Base de Cálculo FL
                          </v-card>
                        </v-card>
                      </div>
                      <v-data-table
                        class="elevation-1 tbResults"
                        :headers="[
                          {
                            text: 'Valor alcançado',
                            align: 'start',
                            value: 'vl_vendido',
                            class: 'black--text title',
                          },
                          {
                            text: 'Faixa Inicial',
                            align: 'start',
                            value: 'fx_inicial',
                          },
                          {
                            text: 'Faixa Final',
                            align: 'start',
                            value: 'fx_final',
                          },
                          {
                            text: 'Porcentagem aplicada',
                            align: 'start',
                            value: 'porcentagem_aplicada',
                          },
                          {
                            text: 'Valor inicial',
                            align: 'start',
                            value: 'vl_inicial',
                          },
                          {
                            text: 'Valor final',
                            align: 'start',
                            value: 'vl_final',
                          },
                          {
                            text: 'Prêmio',
                            align: 'start',
                            value: 'vl_premio',
                            class: 'success--text title',
                          },
                        ]"
                        :items="calculoDadosComissaoVisitacao"
                        hide-default-footer
                      ></v-data-table>
                    </div>

                    <!--Div para premiação Marketing Agendamentos-->
                    <div class="pa-2" v-if="mktAgendamento">
                      <v-card>
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >{{ labelBanner1 }} - Meta:
                          {{ metaIndividualAgendamento }} - Prêmio Agendamento
                          Leads
                        </v-card-title>
                      </v-card>

                      <div class="pa-2">
                        <v-card
                          class="d-flex"
                          color="grey lighten-2"
                          flat
                          height="100"
                          tile
                        >
                          <v-card
                            class="d-flex align-center justify-center"
                            width="25vw"
                            outlined
                            tile
                            color="orange"
                            style="font-size: 0.9rem; font-weight: bold"
                          >
                            PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                            META DE FATURAMENTO)
                          </v-card>

                          <div class="d-flex flex-column">
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                            >
                              <v-row style="margin-left: 0px">
                                <v-col
                                  cols="6"
                                  style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                >
                                  Até
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="background-color: #ff8a65"
                                >
                                  {{ metaAteIndividualAgendamento }}
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                              style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                            >
                              NÃO HÁ PRÊMIAÇÃO
                            </v-card>
                          </div>

                          <v-card
                            class="d-flex align-center justify-center"
                            width="7vw"
                            outlined
                            color="orange"
                            tile
                          >
                            Base de Cálculo FL
                          </v-card>
                        </v-card>
                      </div>
                      <v-data-table
                        class="elevation-1 tbResults"
                        :headers="[
                          {
                            text: 'Valor alcançado',
                            align: 'start',
                            value: 'vl_vendido',
                            class: 'black--text title',
                          },
                          {
                            text: 'Faixa Inicial',
                            align: 'start',
                            value: 'fx_inicial',
                          },
                          {
                            text: 'Faixa Final',
                            align: 'start',
                            value: 'fx_final',
                          },
                          {
                            text: 'Porcentagem aplicada',
                            align: 'start',
                            value: 'porcentagem_aplicada',
                          },
                          {
                            text: 'Valor inicial',
                            align: 'start',
                            value: 'vl_inicial',
                          },
                          {
                            text: 'Valor final',
                            align: 'start',
                            value: 'vl_final',
                          },
                          {
                            text: 'Prêmio',
                            align: 'start',
                            value: 'vl_premio',
                            class: 'success--text title',
                          },
                        ]"
                        :items="calculoDadosComissaoAgendamento"
                        hide-default-footer
                      ></v-data-table>
                    </div>

                    <!--Div para premiação Marketing Agendamentos-->
                    <div class="pa-2" v-if="mktComparecimento">
                      <v-card>
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >{{ labelBanner1 }} - Meta:
                          {{ metaIndividualComparecimento }} - Prêmio
                          Comparecimento Agenda Leads
                        </v-card-title>
                      </v-card>

                      <div class="pa-2">
                        <v-card
                          class="d-flex"
                          color="grey lighten-2"
                          flat
                          height="100"
                          tile
                        >
                          <v-card
                            class="d-flex align-center justify-center"
                            width="25vw"
                            outlined
                            tile
                            color="orange"
                            style="font-size: 0.9rem; font-weight: bold"
                          >
                            PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                            META DE FATURAMENTO)
                          </v-card>

                          <div class="d-flex flex-column">
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                            >
                              <v-row style="margin-left: 0px">
                                <v-col
                                  cols="6"
                                  style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                >
                                  Até
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="background-color: #ff8a65"
                                >
                                  {{ metaAteIndividualComparecimento }}
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                              style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                            >
                              NÃO HÁ PRÊMIAÇÃO
                            </v-card>
                          </div>

                          <v-card
                            class="d-flex align-center justify-center"
                            width="7vw"
                            outlined
                            color="orange"
                            tile
                          >
                            Base de Cálculo FL
                          </v-card>
                        </v-card>
                      </div>
                      <v-data-table
                        class="elevation-1 tbResults"
                        :headers="[
                          {
                            text: 'Valor alcançado',
                            align: 'start',
                            value: 'vl_vendido',
                            class: 'black--text title',
                          },
                          {
                            text: 'Faixa Inicial',
                            align: 'start',
                            value: 'fx_inicial',
                          },
                          {
                            text: 'Faixa Final',
                            align: 'start',
                            value: 'fx_final',
                          },
                          {
                            text: 'Porcentagem aplicada',
                            align: 'start',
                            value: 'porcentagem_aplicada',
                          },
                          {
                            text: 'Valor inicial',
                            align: 'start',
                            value: 'vl_inicial',
                          },
                          {
                            text: 'Valor final',
                            align: 'start',
                            value: 'vl_final',
                          },
                          {
                            text: 'Prêmio',
                            align: 'start',
                            value: 'vl_premio',
                            class: 'success--text title',
                          },
                        ]"
                        :items="calculoDadosComissaoComparecimento"
                        hide-default-footer
                      ></v-data-table>
                    </div>

                    <!--Div para premiação Individual e Administrativa-->
                    <div
                      class="pa-2"
                      v-if="
                        profissionalEscolhido.filter(
                          e => e.ZA2_TIPO === 'I' || e.ZA2_TIPO === 'A',
                        ).length > 0
                      "
                    >
                      <v-card>
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >{{ labelBanner1 }} - Meta: {{ metaIndividual }} -
                          Prêmio Individual
                          {{
                            profissionalEscolhido.filter(
                              e => e.ZA2_TIPO === "X",
                            ).length > 0
                              ? "indicação médica"
                              : profissionalEscolhido.filter(
                                  e => e.ZA2_TIPO === "V",
                                ).length > 0
                              ? "Visitação médica"
                              : ""
                          }}</v-card-title
                        >
                      </v-card>
                      <v-banner
                        v-if="['000009', '000014'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - Indivídual - Meta
                        {{ metaIndividual }}</v-banner
                      >
                      <v-banner
                        v-if="['000025'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - EQUIPES - Meta
                        {{ metaIndividual }}</v-banner
                      >
                      <div class="pa-2">
                        <v-card
                          class="d-flex"
                          color="grey lighten-2"
                          flat
                          height="100"
                          tile
                        >
                          <v-card
                            class="d-flex align-center justify-center"
                            width="25vw"
                            outlined
                            tile
                            color="orange"
                            style="font-size: 0.9rem; font-weight: bold"
                          >
                            PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                            META DE FATURAMENTO)
                          </v-card>

                          <div class="d-flex flex-column">
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                            >
                              <v-row style="margin-left: 0px">
                                <v-col
                                  cols="6"
                                  style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                >
                                  Até
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="background-color: #ff8a65"
                                >
                                  {{ metaAteIndividual }}
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                              style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                            >
                              NÃO HÁ PRÊMIAÇÃO
                            </v-card>
                          </div>

                          <v-card
                            class="d-flex align-center justify-center"
                            width="7vw"
                            outlined
                            color="orange"
                            tile
                          >
                            Base de Cálculo FL
                          </v-card>
                        </v-card>
                      </div>
                      <v-data-table
                        class="elevation-1 tbResults"
                        :headers="[
                          {
                            text: 'Valor',
                            align: 'start',
                            value: 'vl_vendido',
                            class: 'black--text title',
                          },
                          {
                            text: 'Faixa Inicial',
                            align: 'start',
                            value: 'fx_inicial',
                          },
                          {
                            text: 'Faixa Final',
                            align: 'start',
                            value: 'fx_final',
                          },
                          {
                            text: 'Porcentagem aplicada',
                            align: 'start',
                            value: 'porcentagem_aplicada',
                          },
                          {
                            text: 'Valor inicial',
                            align: 'start',
                            value: 'vl_inicial',
                          },
                          {
                            text: 'Valor final',
                            align: 'start',
                            value: 'vl_final',
                          },
                          {
                            text: 'Prêmio',
                            align: 'start',
                            value: 'vl_premio',
                            class: 'success--text title',
                          },
                        ]"
                        :items="calculoDadosComissao"
                        hide-default-footer
                      ></v-data-table>

                      <div
                        class="d-flex justify-end alig-center"
                        v-if="labelDivisaoindividual"
                      >
                        <span class="caption red--text mt-2 mr-5">{{
                          labelDivisaoindividual
                        }}</span>
                      </div>
                    </div>

                    <!--Div para premiação opgrade-->
                    <div
                      class="pa-2"
                      v-if="
                        profissionalEscolhido.filter(e => e.ZA2_TIPO === 'O')
                          .length > 0
                      "
                    >
                      <v-card>
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >{{ labelBanner1 }} - Meta:
                          {{ metaIndividualOpGrade }} - Prêmio
                          OpGrade</v-card-title
                        >
                      </v-card>
                      <v-banner
                        v-if="['000009', '000014'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - Indivídual - Meta
                        {{ metaIndividualOpGrade }}</v-banner
                      >
                      <v-banner
                        v-if="['000025'].includes(tipoVendedor)"
                        class="mt-2"
                        rounded
                        color="cyan"
                        single-line
                        >Prêmio Trimestral - EQUIPES - Meta
                        {{ metaIndividualOpGrade }}</v-banner
                      >
                      <div class="pa-2">
                        <v-card
                          class="d-flex"
                          color="grey lighten-2"
                          flat
                          height="100"
                          tile
                        >
                          <v-card
                            class="d-flex align-center justify-center"
                            width="25vw"
                            outlined
                            tile
                            color="orange"
                            style="font-size: 0.9rem; font-weight: bold"
                          >
                            PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                            META DE FATURAMENTO)
                          </v-card>

                          <div class="d-flex flex-column">
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                            >
                              <v-row style="margin-left: 0px">
                                <v-col
                                  cols="6"
                                  style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                >
                                  Até
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="background-color: #ff8a65"
                                >
                                  {{ metaAteIndividualOpGrade }}
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card
                              class="d-flex align-center justify-center"
                              width="60vw"
                              height="50vh"
                              outlined
                              tile
                              style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                            >
                              NÃO HÁ PRÊMIAÇÃO
                            </v-card>
                          </div>

                          <v-card
                            class="d-flex align-center justify-center"
                            width="7vw"
                            outlined
                            color="orange"
                            tile
                          >
                            Base de Cálculo FL
                          </v-card>
                        </v-card>
                      </div>
                      <v-data-table
                        class="elevation-1 tbResults"
                        :headers="[
                          {
                            text: 'Valor vendido',
                            align: 'start',
                            value: 'vl_vendido',
                            class: 'black--text title',
                          },
                          {
                            text: 'Faixa Inicial',
                            align: 'start',
                            value: 'fx_inicial',
                          },
                          {
                            text: 'Faixa Final',
                            align: 'start',
                            value: 'fx_final',
                          },
                          {
                            text: 'Porcentagem aplicada',
                            align: 'start',
                            value: 'porcentagem_aplicada',
                          },
                          {
                            text: 'Valor inicial',
                            align: 'start',
                            value: 'vl_inicial',
                          },
                          {
                            text: 'Valor final',
                            align: 'start',
                            value: 'vl_final',
                          },
                          {
                            text: 'Prêmio',
                            align: 'start',
                            value: 'vl_premio',
                            class: 'success--text title',
                          },
                        ]"
                        :items="calculoDadosComissaoOpGrade"
                        hide-default-footer
                      ></v-data-table>

                      <div
                        class="d-flex justify-end alig-center"
                        v-if="labelDivisaoOpgrade"
                      >
                        <span class="caption red--text mt-2 mr-5">{{
                          labelDivisaoOpgrade
                        }}</span>
                      </div>
                    </div>

                    <!--Div para premiação Equipe-->
                    <div
                      v-if="
                        profissionalEscolhido.filter(e => e.ZA2_TIPO === 'E')
                          .length > 0 && dadosDoCalculoEquipe.length > 0
                      "
                    >
                      <div
                        class="pa-2"
                        v-for="item in dadosDoCalculoEquipe"
                        :key="item.nome"
                      >
                        <v-card-title
                          class="justify-center align-center"
                          style="background-color: #3949ab; color: white"
                          >Prêmio Trimestral - {{ item.nome }} - Meta
                          {{ item.meta }}</v-card-title
                        >
                        <div>
                          <v-data-table
                            class="elevation-1 tbResults"
                            dense
                            :headers="headersEquipeDetVendedores"
                            :items="item.detalhamento"
                            fixed-header
                            :items-per-page="item.detalhamento.length"
                            :height="
                              item.detalhamento.length <= 1 ? '10vh' : '30vh'
                            "
                            hide-default-footer
                          ></v-data-table>
                        </div>
                        <div class="pa-2">
                          <v-card
                            class="d-flex"
                            color="grey lighten-2"
                            flat
                            height="100"
                            tile
                          >
                            <v-card
                              class="d-flex align-center justify-center"
                              width="25vw"
                              outlined
                              tile
                              color="orange"
                              style="font-size: 0.9rem; font-weight: bold"
                            >
                              PARA DESEMPENHOS REGULARES OU ORDINÁRIOS (CONFORME
                              META DE FATURAMENTO)
                            </v-card>

                            <div class="d-flex flex-column">
                              <v-card
                                class="d-flex align-center justify-center"
                                width="60vw"
                                height="50vh"
                                outlined
                                tile
                              >
                                <v-row style="margin-left: 0px">
                                  <v-col
                                    cols="6"
                                    style="
                                    background-color: #ff8a65;
                                    border-right: 1px solid grey;
                                  "
                                  >
                                    Até
                                  </v-col>
                                  <v-col
                                    cols="6"
                                    style="background-color: #ff8a65"
                                  >
                                    {{ item.metaAte }}
                                  </v-col>
                                </v-row>
                              </v-card>
                              <v-card
                                class="d-flex align-center justify-center"
                                width="60vw"
                                height="50vh"
                                outlined
                                tile
                                style="
                                font-weight: bold;
                                text-decoration: underline;
                                background-color: #ff8a65;
                              "
                              >
                                NÃO HÁ PRÊMIAÇÃO
                              </v-card>
                            </div>

                            <v-card
                              class="d-flex align-center justify-center"
                              width="7vw"
                              outlined
                              color="orange"
                              tile
                            >
                              Base de Cálculo FL
                            </v-card>
                          </v-card>
                        </div>

                        <v-data-table
                          class="elevation-1 tbResults"
                          :headers="[
                            {
                              text: 'Valor vendido',
                              align: 'start',
                              value: 'vl_vendido',
                              class: 'black--text title',
                            },
                            {
                              text: 'Faixa Inicial',
                              align: 'start',
                              value: 'fx_inicial',
                            },
                            {
                              text: 'Faixa Final',
                              align: 'start',
                              value: 'fx_final',
                            },
                            {
                              text: 'Porcentagem aplicada',
                              align: 'start',
                              value: 'porcentagem_aplicada',
                            },
                            {
                              text: 'Valor inicial',
                              align: 'start',
                              value: 'vl_inicial',
                            },
                            {
                              text: 'Valor final',
                              align: 'start',
                              value: 'vl_final',
                            },
                            {
                              text: 'Prêmio',
                              align: 'start',
                              value: 'vl_premio',
                              class: 'success--text title',
                            },
                          ]"
                          :items="item.calculo"
                          hide-default-footer
                        ></v-data-table>
                        <div
                          class="d-flex justify-end alig-center"
                          v-if="labelDivisaoEquipe"
                        >
                          <span class="caption red--text mt-2 mr-5">{{
                            labelDivisaoEquipe
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div></v-container
        ></v-tab-item
      >
      <!-- RESUMO -->
      <v-tab-item
        ><v-container fluid>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn
              x-small
              :disabled="resumoFechado || !anoEscolhido || !trimestre"
              class="mx-2 white--text red darken-2"
              @click="
                () => {
                  dialogFecharResumo = true;
                }
              "
              >Fechar Resumo
            </v-btn>
          </v-row>
          <v-data-table
            dense
            fixed-header
            :headers="headersResumo"
            height="78vh"
            hide-default-footer
            :items="itemsResumo"
            :items-per-page="itemsResumo.length"
            :search="buscaResumo"
          >
            <template v-slot:top>
              <div class="d-flex justify-center align-center">
                <v-row no-gutters>
                  <v-col cols="1" class="ma-2">
                    <v-select
                      class="rounded-lg"
                      v-model="anoEscolhido"
                      :loading="loadingAnos"
                      :items="anos"
                      label="Escolha o ano"
                      @change="buscaPessoas"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="ma-2">
                    <v-select
                      class="rounded-lg"
                      v-model="trimestre"
                      :items="[
                        { text: '1 trimestre', value: '0101-0331' },
                        { text: '2 trimestre', value: '0401-0630' },
                        { text: '3 trimestre', value: '0701-0930' },
                        { text: '4 trimestre', value: '1001-1231' },
                      ]"
                      label="Escolha o Trimestre"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="3"
                    class="d-flex justify-center align-center ml-2"
                  >
                    <v-text-field
                      v-model="buscaResumo"
                      dense
                      hide-details
                      label="Pesquisar"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex justify-center align-center ml-2"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      :disabled="showSkelekton"
                      class="mx-2 primary"
                      @click="newCalculaResumo"
                      >Carregar Resumo</v-btn
                    >
                  </v-col>
                  <vue-excel-xlsx
                    :data="itemsResumoExcel"
                    :columns="headersResumoExcel"
                    filename="Resumo Premio"
                    :sheetname="`Resumo`"
                    class="ml-5 mb-5 tbExcel"
                  >
                    <v-btn icon color="blue darken-2" class="mr-5 mt-5">
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                  </vue-excel-xlsx>
                </v-row>
              </div>
            </template>
            <template v-slot:[`item.REALIZADOCURR`]="{ item }">
              <span>{{ item.REALIZADOCURR }}</span>
            </template>
            <template v-slot:[`item.VARIACAO`]="{ item }">
              <v-icon
                class="pa-1"
                v-if="item.VARIACAO >= 0 && item.VARIACAO <= 0.1"
                color="green darken-2"
              >
                mdi-thumb-up
              </v-icon>
              <v-icon
                class="pa-1"
                v-if="item.VARIACAO < 0 && item.VARIACAO > -0.1"
                color="yellow darken-3"
              >
                mdi-thumb-down
              </v-icon>
              <v-icon
                class="pa-1"
                v-if="item.VARIACAO < -0.1 || item.VARIACAO > 0.1"
                color="orange darken-3"
              >
                mdi-alert
              </v-icon>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex justify-center align-center">
                <v-icon color="red" @click="deletaPremio(item)"
                  >mdi-delete</v-icon
                >
              </div>
            </template>
            <template v-slot:[`item.PERCENT`]="{ item }">
              <v-progress-linear
                dark
                indeterminate
                color="blue"
                height="22"
                v-if="!item.REALIZADO"
                readonly
                ><template v-slot:default>
                  <strong>Calculando...</strong>
                </template></v-progress-linear
              >
              <v-progress-linear
                v-else
                :dark="item.PERCENT > 50"
                v-model="item.PERCENT"
                :color="validaCor(item.PERCENT)"
                style="pointer-events: none"
                height="22"
                readonly
                stream
                ><template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template></v-progress-linear
              >
            </template>
            <template v-slot:footer>
              <div class="d-flex justify-end align-center mr-5">
                <span>Total: {{ totalGeralResumo }}</span>
              </div>
            </template>
          </v-data-table>
        </v-container></v-tab-item
      >
    </v-tabs>

    <div>
      <v-dialog v-model="showSkelekton" width="45vw" persistent>
        <v-card>
          <v-card-title>
            <span class="title">{{ titlePremioCalc }}</span>
            <v-spacer></v-spacer>
            <v-progress-circular
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </v-card-title>

          <v-card-text>
            <div
              v-if="numberofEquipes > 1"
              class="d-flex justify-center aling-center"
            >
              <v-progress-linear
                v-model="andamento"
                height="18"
                stream
                :dark="andamento > 50"
                ><template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template></v-progress-linear
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="loading" width="300px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <span class="title">Aguarde</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column align-center">
          <span class="h5 mb-2">Gravando informações</span>
          <v-progress-circular
            :width="3"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRefinamento" persistent width="550px">
      <v-card>
        <v-card-title>Refinamento</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6">
              <v-text-field
                v-model.lazy="refineValue"
                v-money="money"
                label="Valor do refinamento"
                class="text-money my-1 mx-05 font-08"
                dense
                type="text"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="obsRefinamento"
                label="Observação"
                counter="254"
                maxlength="254"
                rows="4"
                row-height="30"
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="error"
            small
            @click="
              () => {
                dialogRefinamento = !dialogRefinamento;
                refineValue = '';
              }
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="success" small @click="handleRefine">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFecharResumo" persistent width="40vw">
      <v-card>
        <v-card-title>Fechamento do Resumo</v-card-title>
        <v-card-text>
          <p v-if="dialogFecharResumo" class="align-center">
            Tem certeza que quer fechar o resumo do trimestre
            {{ `${anoEscolhido}${trimestreEscolhido}` }}?
            <br />
            Uma vez fechado não poderá mais ser aberto.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="error"
            small
            @click="dialogFecharResumo = !dialogFecharResumo"
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="success" small @click="handleFecharResumo"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInfo" persistent width="40vw">
      <v-card>
        <v-card-title>{{ tituloCard }}</v-card-title>
        <v-card-text
          ><p style="font-weight: bold">{{ mensagem }}</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" small @click="dialogInfo = !dialogInfo"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEmail" persistent width="35vw">
      <v-card>
        <v-card-title>Envio de e-mail com anexo!</v-card-title>
        <v-card-subtitle>Premiação Trimestral</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <v-text-field
              :readonly="aguardarEnvio"
              v-model="emailEnvio"
              label="Digite o(s) email(s) para enviar"
              dense
            ></v-text-field>
            <v-progress-circular
              class="ml-3"
              v-if="aguardarEnvio"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="aguardarEnvio"
            class="error"
            @click="dialogEmail = false"
            x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="aguardarEnvio"
            class="success"
            @click="handleEnviaEmail"
            x-small
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { add, sub } from "date-fns";
import dinero from "dinero.js";
import { VMoney } from "v-money";
import { mapState } from "vuex";

// const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

export default {
  name: "Comissoes",
  directives: { money: VMoney },
  data: () => {
    return {
      mktComparecimento: false,
      metaIndividualComparecimento: 0,
      metaAteIndividualComparecimento: 0,
      calculoDadosComissaoComparecimento: [],
      metaIndividualAgendamento: 0,
      metaAteIndividualAgendamento: 0,
      calculoDadosComissaoAgendamento: [],
      mktAgendamento: false,
      prospeccaoVisitacao: false,
      metaIndividualVisitacao: 0,
      metaAteIndividualVisitacao: 0,
      calculoDadosComissaoVisitacao: [],
      prospeccaoIndicacao: false,
      metaIndividualIndicacao: 0,
      metaAteIndividualIndicacao: 0,
      calculoDadosComissaoIndicacao: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
      loading: false,
      labelDivisaoindividual: null,
      labelDivisaoOpgrade: null,
      labelDivisaoEquipe: null,
      aguardarEnvio: false,
      dialogEmail: false,
      emailEnvio: null,
      dadosFatOpgrade: {},
      dadosFatIndividual: {},
      totalPremioIndividual: 0,
      totalPremioOpGrade: 0,
      totalPremioEquipe: 0,
      totalPremioGeral: 0,
      metaAteIndividualOpGrade: null,
      valorTotalDetalhamentoOpGrade: null,
      totalGeralResumo: 0,
      headersResumo: [
        { text: "Ano / Trimestre", value: "ZAA_ANOTRI" },
        { text: "Área", value: "A3_ZZAREA" },
        { text: "Loja", value: "ZAA_LOJA" },
        { text: "Vendedor", value: "VENDEDOR" },
        { text: "Profissional", value: "ZAA_PROFIS" },
        { text: "Prêmio", value: "VALOR_PREMIO", align: "end" },
        {
          text: "Variação trimestre anterior",
          value: "VARIACAO",
          align: "center",
        },
        { text: "Ações", value: "action" },
      ],
      premioAdmin: false,
      headersResumoExcel: [
        { label: "Ano / Trimestre", field: "ZAA_ANOTRI" },
        { label: "Área", field: "A3_ZZAREA" },
        { label: "Loja", field: "ZAA_LOJA" },
        { label: "Vendedor", field: "VENDEDOR" },
        { label: "Profissional", field: "ZAA_PROFIS" },
        { label: "Prêmio", field: "ZAA_VALOR", align: "end" },
      ],
      itemsResumo: [],
      itemsResumoExcel: [],
      buscaResumo: null,
      numberofEquipes: 0,
      andamento: 0,
      anos: [],
      loadingAnos: true,
      titlePremioCalc: null,
      totalGeralEquipes: 0,
      tipoVendedor: null,
      headersEquipeDetVendedores: [
        { text: "Vendedor", align: "start", sortable: true, value: "VENDEDOR" },
        {
          text: "Valor Vendido",
          align: "end",
          sortable: true,
          value: "FATURAMENTO",
        },
      ],
      totalGeralPremio: 0,
      totalGeralPremioOpGrade: 0,
      totaldePessoas: 1,
      dadosDoCalculoEquipe: [],
      equipe: null,
      itemsRelatExcel: [],
      headersRelatExcel: [],
      nameRelatExcel: null,
      searchDetalhe: null,
      switchDetalhe: false,
      anoEscolhido: null,
      trimestre: null,
      vendedores: [],
      vendedor: null,
      labelBanner1: null,
      showSkelekton: false,
      dialogFecharResumo: false,
      resumoFechado: true,
      dialogRefinamento: false,
      refineValue: "",
      obsRefinamento: "",
      totalRefinamento: 0,
      textDisabled: "grey--text text--lighten-3",
      showDados: false,
      numberLines: 0,
      valorTotalDetalhamento: null,
      headersDetalhamento: [],
      itemsDetalhamento: [],
      calculoDadosComissao: [],
      calculoDadosComissaoOpGrade: [],
      metaIndividual: null,
      metaAteIndividual: null,
      metaIndividualOpGrade: null,
      dialogInfo: false,
      tituloCard: null,
      mensagem: null,
      metaEquipe: null,
      metaAteEquipe: null,
      countEquipes: 0,
      calculoDadosComissaoEquipe: [],
      dadosVendedoresGeral: [],
      profissionalEscolhido: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      faturamentoIndividual: state => state.premiacao.faturamentoIndividual,
      dadosCalculoIndividual: state => state.premiacao.dadosCalculoIndividual,
    }),
    trimestreEscolhido() {
      if (this.trimestre.substring(0, 2) == "01") return "01";
      if (this.trimestre.substring(0, 2) == "04") return "02";
      if (this.trimestre.substring(0, 2) == "07") return "03";
      if (this.trimestre.substring(0, 2) == "10") return "04";
      return "";
    },
  },
  methods: {
    toCurrency(number) {
      return number.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    validaCor(valor) {
      if (valor <= 25) {
        return "red accent-4";
      }
      if (valor > 25 && valor <= 50) {
        return "yellow accent-4";
      }
      if (valor > 50 && valor <= 75) {
        return "blue accent-4";
      }
      if (valor > 75) {
        return "green accent-4";
      }
    },
    async deletaPremio(item) {
      const dadosTabAux = {
        ZAA_ANOTRI: item.ZAA_ANOTRI,
        ZAA_CODVEN: item.ZAA_CODVEN,
        D_E_L_E_T_: "*",
        R_E_C_D_E_L_: item.RECNO,
      };

      const urlAux = `${process.env.VUE_APP_BASE_API_URL}/addAuxPremio`;
      await axios.post(urlAux, dadosTabAux);

      this.newCalculaResumo();
    },
    async handleRefine() {
      this.loading = true;
      const anoTrimestre = `${this.anoEscolhido}${this.trimestreEscolhido}`;
      const vendedor = this.vendedor;

      this.totalRefinamento =
        Number(this.refineValue.replace(/[^-\d]/g, "")) / 100;

      const url = `${process.env.VUE_APP_BASE_API_URL}/refinamentos/${anoTrimestre}/${vendedor}`;
      const dados = {
        ZA6_VALOR: this.totalRefinamento,
        ZA6_OBS: this.obsRefinamento,
        ZA6_USRALT: this.user.usr_protheus,
      };

      try {
        await axios.put(url, dados);

        this.dialogRefinamento = false;
        this.refineValue = "";

        this.totalPremioGeral =
          this.totalPremioIndividual +
          this.totalPremioOpGrade +
          this.totalPremioEquipe +
          this.totalRefinamento;

        const dadosAtualizados = {
          ZAA_ANOTRI: `${this.anoEscolhido}${this.trimestreEscolhido}`,
          ZAA_CODVEN: this.vendedor,
          ZAA_VALOR: this.totalPremioGeral,
        };
        const urlAux = `${process.env.VUE_APP_BASE_API_URL}/addAuxPremio`;
        await axios.post(urlAux, dadosAtualizados);

        this.$toast.success("Refinamento gravado com sucesso!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro na inclusão do refinamento", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.loading = false;
    },
    async handleFecharResumo() {
      this.loading = true;
      const anoTrimestre = `${this.anoEscolhido}${this.trimestreEscolhido}`;

      const url = `${process.env.VUE_APP_BASE_API_URL}/resumos/${anoTrimestre}/close`;

      try {
        await axios.put(url);
        this.resumoFechado = true;
        this.dialogFecharResumo = false;

        this.$toast.success("Resumo fechado!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Não foi possível fechar o resumo.", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      this.loading = false;
    },

    async newCalculaResumo() {
      if (this.vendedores.length <= 0) return;

      if (!this.trimestre) return;

      this.itemsResumo = [];

      let trimestre = "";
      let trimestreAnterior = "";

      if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
        trimestre = `${this.anoEscolhido}01`;
        trimestreAnterior = `${this.anoEscolhido - 1}04`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
        trimestre = `${this.anoEscolhido}02`;
        trimestreAnterior = `${this.anoEscolhido}01`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
        trimestre = `${this.anoEscolhido}03`;
        trimestreAnterior = `${this.anoEscolhido}02`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
        trimestre = `${this.anoEscolhido}04`;
        trimestreAnterior = `${this.anoEscolhido}03`;
      }

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZMA_DESCRI,A3_ZZAREA,A3_NOME,ZAA.ZAA_ANOTRI,ZAA.ZAA_CODVEN,ZAA.ZAA_LOJA,ZAA.ZAA_PROFIS,ZAA.ZAA_VALOR,ZAAA.ZAA_VALOR ZAA_VALORANT,ZAA.R_E_C_N_O_ RECNO,ZAA.ZAA_FECHAD,ZA6_OBS,ZA6_VALOR`,
        INNER: `INNER JOIN SA3010 SA3 WITH(NOLOCK) ON
                              SA3.D_E_L_E_T_=' '
                              AND A3_COD=ZAA.ZAA_CODVEN
                LEFT JOIN ZMA010 ZMA WITH(NOLOCK) ON
                              ZMA.D_E_L_E_T_=' '
                              AND ZMA_IDAREA=A3_ZZAREA
                LEFT JOIN ZAA010 ZAAA WITH(NOLOCK) ON
                              ZAAA.D_E_L_E_T_=' '
                              AND ZAAA.ZAA_CODVEN = ZAA.ZAA_CODVEN
                              AND ZAAA.ZAA_ANOTRI = '${trimestreAnterior}'
                LEFT JOIN ZA6010 ZA6 WITH(NOLOCK) ON
                              ZA6.D_E_L_E_T_=' '
                              AND ZA6.ZA6_CODVEN = ZAA.ZAA_CODVEN
                              AND ZA6.ZA6_ANOTRI = ZAA.ZAA_ANOTRI `,
        WHERE: `ZAA.ZAA_ANOTRI='${trimestre}' ORDER BY A3_NOME`,
        TABELA: `ZAA`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const premio = await axios.post(url, dados);

      this.totalGeralResumo = 0;
      this.resumoFechado = !premio.data.some(e => e.ZAA_FECHAD !== "S");
      console.log("resumoFechado", this.resumoFechado);

      premio.data.map(e => {
        e.VALOR_PREMIO = e.ZAA_VALOR.toLocaleString("pt-BR", {
          currency: "BRL",
          style: "currency",
        });
        this.totalGeralResumo += e.ZAA_VALOR;
        e.VENDEDOR = `${e.ZAA_CODVEN}-${e.A3_NOME}`;
        e.A3_ZZAREA = `${e.A3_ZZAREA}-${e.ZMA_DESCRI}`;
        e.VARIACAO = Number(
          ((e.ZAA_VALOR || 0) - (e.ZAA_VALORANT || 0)) /
            (e.ZAA_VALOR || 1).toFixed(2),
        );
        this.obsRefinamento = e.ZA6_OBS;
        this.refineValue = e.ZA6_VALOR
          ? e.ZA6_VALOR.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      });

      this.itemsResumo = premio.data;
      this.itemsResumoExcel = premio.data;
      this.totalGeralResumo = this.totalGeralResumo.toLocaleString("pt-BR", {
        currency: "BRL",
        style: "currency",
      });
    },
    async calculaResumo() {
      this.itensResumo = [];

      this.itemsResumo = this.dadosVendedoresGeral.map(e => {
        let META;
        let METAFLOAT = 0;
        if (this.trimestre === "0101-0331") {
          META = e.ZA2_META1.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META1;
        }
        if (this.trimestre === "0401-0630") {
          META = e.ZA2_META2.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META2;
        }
        if (this.trimestre === "0701-0930") {
          META = e.ZA2_META3.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META3;
        }
        if (this.trimestre === "1001-1231") {
          META = e.ZA2_META4.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          METAFLOAT = e.ZA2_META4;
        }
        return {
          VENDEDOR: `${e.ZA2_CODVEN}-${e.A3_NOME.trim()}`,
          CARGO: e.ZA9_DESCRI,
          TIPO: e.ZA2_TIPO === "I" ? "INDIVIDUAL" : "EQUIPE",
          META,
          METAFLOAT,
          REALIZADO: 0,
          ...e,
        };
      });

      await Promise.all(
        this.itemsResumo.map(async item => {
          if (item.ZA2_TIPO === "I") {
            item.REALIZADO = await this.calculaResumoIndividual(item);
            if (item.REALIZADO <= 0) {
              item.REALIZADO = "-";
              item.REALIZADOCURR = "-";
              item.PERCENT = 0;
            } else {
              item.REALIZADOCURR = item.REALIZADO.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              item.PERCENT = ((item.REALIZADO * 100) / item.METAFLOAT).toFixed(
                2,
              );
            }
          }
          if (item.ZA2_TIPO === "E") {
            item.REALIZADO = await this.calculaResumoEquipe(item);
            if (item.REALIZADO <= 0) {
              item.REALIZADO = "-";
              item.REALIZADOCURR = "-";
              item.PERCENT = 0;
            } else {
              item.REALIZADOCURR = item.REALIZADO.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              item.PERCENT = ((item.REALIZADO * 100) / item.METAFLOAT).toFixed(
                2,
              );
            }
          }
          if (item.METAFLOAT <= 0) {
            item.REALIZADO = "-";
            item.REALIZADOCURR = "-";
            item.PERCENT = 0;
          }
        }),
      );
    },
    async calculaResumoEquipe(vendedor) {
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZA7_CODVEN`,
        INNER: `INNER JOIN ZA8010 ZA8 WITH(NOLOCK) ON
                            ZA8.D_E_L_E_T_=' '
                            AND ZA8_IDREG=ZA2_ID

                INNER JOIN ZA7010 ZA7 WITH(NOLOCK) ON
                            ZA7.D_E_L_E_T_=' '
                            AND ZA7_IDCAB=ZA8_IDEQP `,
        WHERE: `ZA2_ID='${vendedor.ZA2_ID}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='E'`,
        TABELA: `ZA2`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const vendedoresEquipes = await axios.post(url, dados);

      let vendedores = "";

      vendedoresEquipes.data.map(e => {
        vendedores += `'${e.ZA7_CODVEN}',`;
      });

      vendedores = vendedores.substring(0, vendedores.length - 1);

      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;
      const dtFinal = `${this.anoEscolhido}${datas[1]}`;

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "SUM(FATURAMENTO_LIQUIDO) FATURAMENTO",
        WHERE: `CHAVE_VENDEDOR IN (${vendedores}) AND DATA_EMISSAO BETWEEN '${dtInicial}' AND '${dtFinal}' `,
        TABELA: "DB_V_RECEITAS_BI",
      };

      const faturamento = await axios.post(url, dados);

      if (faturamento.data.length > 0) {
        return faturamento.data[0].FATURAMENTO;
      } else {
        return 0;
      }
    },
    async calculaResumoIndividual(vendedor) {
      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;
      const dtFinal = `${this.anoEscolhido}${datas[1]}`;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
        WHERE: ` CHAVE_VENDEDOR='${vendedor.ZA2_CODVEN}' AND DATA_EMISSAO BETWEEN '${dtInicial}' AND '${dtFinal}' `,
        TABELA: `DB_V_RECEITAS_BI`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const realizado = await axios.post(url, dados);

      if (realizado.data.length > 0) {
        return realizado.data[0].FATURAMENTO_LIQUIDO;
      } else {
        return 0;
      }
    },
    setVendedor(item) {
      this.profissionalEscolhido = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === item,
      );

      this.showDados = false;
      this.tipoVendedor = item.A3_ZTIPO;
      this.labelBanner1 = `${item.Z0_DESC} - ${item.A3_NOME} - ${item.ZMA_DESCRI}`;
    },
    async calcula() {
      this.calculoDadosComissaoAgendamento = [];
      this.calculoDadosComissaoComparecimento = [];
      this.mktAgendamento = false;
      this.mktComparecimento = false;
      this.obsRefinamento = "";
      this.dadosDoCalculoEquipe = [];
      this.premioAdmin = false;
      this.prospeccaoIndicacao = false;
      this.metaIndividualIndicacao = 0;
      this.metaAteIndividualIndicacao = 0;
      this.calculoDadosComissaoIndicacao = [];
      this.prospeccaoVisitacao = false;
      this.metaIndividualVisitacao = 0;
      this.metaAteIndividualVisitacao = 0;
      this.calculoDadosComissaoVisitacao = [];
      this.totalGeralPremio = 0;
      this.totalPremioIndividual = 0;
      this.totalPremioOpGrade = 0;
      this.totalPremioEquipe = 0;
      this.totalRefinamento = 0;
      this.totaldePessoas = 1;
      this.countEquipes = 0;

      if (!this.anoEscolhido || !this.trimestre || !this.vendedor) {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem =
          "Escolha o ano, trimestre e vendedor para fazer o cálculo!";
        return;
      }

      this.showSkelekton = true;
      this.showDados = false;

      const url1 = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let dados1 = {
        FIELDS: "A3_ZTIPO,A3_FILREF,A3_NOME",
        WHERE: `A3_COD='${this.vendedor}' `,
        TABELA: "SA3",
      };

      const dadosVendedor = await axios.post(url1, dados1);

      if (dadosVendedor.data.length <= 0) {
        this.$toast.error(
          "Não achei os dados deste vendedor. Tipo e Filial de Referência!",
          {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          },
        );
        return;
      }

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor,
      );

      if (profissional.length <= 0) {
        this.$toast.error("Sem identificação do profissional!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dados = {
        FIELDS: "ZA9_COD",
        WHERE: `ZA9_COD='${profissional[0].ZA2_PROFIS}'`,
        TABELA: "ZA9",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const profissionais = await axios.post(url, dados);

      if (profissionais.data.length <= 0) {
        this.$toast.error("Código do profissional não encontrado!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      /* refinamento */
      const refinamentoTrimestre = await axios.post(url, {
        FIELDS: "*",
        WHERE: `ZA6_ANOTRI='${this.anoEscolhido}${this.trimestreEscolhido}'
            AND ZA6_CODVEN='${this.vendedor}'`,
        TABELA: "ZA6",
      });
      if (refinamentoTrimestre && refinamentoTrimestre.data.length > 0) {
        this.totalRefinamento = refinamentoTrimestre.data[0].ZA6_VALOR;
        this.refineValue = refinamentoTrimestre.data[0].ZA6_VALOR.toLocaleString(
          "pt-BR",
          {
            currency: "BRL",
            style: "currency",
          },
        );
        this.obsRefinamento = refinamentoTrimestre.data[0].ZA6_OBS;
      }

      this.showSkelekton = true;
      this.showDados = false;

      this.labelBanner1 = ``;

      this.itemsDetalhamento = [];
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.numberofEquipes = 0;
      this.dadosDoCalculoEquipe = [];
      this.totalGeralPremioOpGrade = 0;
      this.metaIndividualOpGrade = 0;
      this.calculoDadosComissaoOpGrade = [];

      for await (const item of profissional) {
        if (item.ZA2_TIPO === "E") {
          this.labelBanner1 = `${
            item.ZA2_CODVEN
          }-${item?.A3_NOME.trim()} (${item?.ZA9_DESCRI.trim()})`;
        } else {
          this.labelBanner1 = `${item.ZA2_CODVEN}-${item?.A3_NOME.trim()}`;
        }
        if (item.ZA2_TIPO === "I") {
          await this.premioIndividual(item);
        }

        if (item.ZA2_TIPO === "O") {
          await this.premioOpgrade(item);
        }

        if (item.ZA2_TIPO === "E") {
          this.countEquipes++;
          const datas = this.trimestre.split("-");
          const dtInicial = `${this.anoEscolhido}${datas[0]}`;

          this.calculoDadosComissaoEquipe = [];
          this.metaEquipe = null;
          this.metaAteEquipe = null;

          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZA8_IDEQP,ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZXG_QTDP,ZXG_PESO,ZA2_TIPO,ZA3_PBASE,ZA3_TIPOV,ZA2_TPMETA,ZXG_LOJA`,
            INNER: `  INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
              this.trimestreEscolhido,
            )}'
                      INNER JOIN ZA8010 ZA8 WITH(NOLOCK) ON ZA8.D_E_L_E_T_=' ' AND ZA8_IDREG=ZXG_ID
                      INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA3.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID `,
            WHERE: `ZA2_CODVEN='${item.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='E' AND ZA2_ID='${item.ZA2_ID}'`,
            TABELA: `ZA2`,
          };

          this.titlePremioCalc = "Calculando prêmio equipe...";

          const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          let dadosComissaoEquipe = [];
          await axios
            .post(url, dados)
            .then(res => {
              dadosComissaoEquipe = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });

          if (dadosComissaoEquipe.length <= 0) {
            this.dialogInfo = true;
            this.tituloCard = "Erro";
            this.mensagem = "Não achei dados para o cálculo do prêmio EQUIPE!";
          }

          await this.calculoEquipe(dadosComissaoEquipe, dtInicial);
        }

        if (item.ZA2_TIPO === "A") {
          await this.premioAdm(item);
        }

        if (item.ZA2_TIPO === "F") {
          await this.premioMktAgendamento();
        }

        if (item.ZA2_TIPO === "G") {
          await this.premioMktComparecimento();
        }

        // if (item.ZA2_TIPO === "X") {
        //   await this.premioProspeIndica();
        // }

        // if (item.ZA2_TIPO === "V") {
        //   await this.premioProspeVisita();
        // }
      }

      /* prospector */
      for (const item of profissional) {
        if (item.ZA2_TIPO === "P") {
          this.countEquipes++;

          this.calculoDadosComissaoEquipe = [];
          this.metaEquipe = null;
          this.metaAteEquipe = null;

          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZA8_IDEQP,ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZXG_QTDP,ZXG_PESO,ZA2_TIPO,ZA3_PBASE,ZA3_TIPOV,ZA5_LOJA,ZA2_TPMETA,ZXG_LOJA`,
            INNER: `
                    INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
                      this.trimestreEscolhido,
                    )}'
                    INNER JOIN ZA8010 ZA8 WITH(NOLOCK) ON ZA8.D_E_L_E_T_=' ' AND ZA8_IDREG=ZXG_ID
                    INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA8.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID
                    INNER JOIN ZA5010 ZA5 WITH(NOLOCK) ON ZA5.D_E_L_E_T_=' ' AND ZA5_COD=ZA8_IDEQP `,
            WHERE: `ZA2_CODVEN='${item.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='P' AND ZA2_ID='${item.ZA2_ID}'`,
            TABELA: `ZA2`,
          };

          this.titlePremioCalc = "Calculando prêmio equipe prospecção...";

          const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          let dadosComissaoEquipe = [];
          let dadosComissaoEquipeOld = [];
          await axios
            .post(url, dados)
            .then(res => {
              dadosComissaoEquipe = res.data;
              dadosComissaoEquipeOld = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });

          dadosComissaoEquipe = [
            ...new Set(dadosComissaoEquipe.map(e => e.ZA8_IDEQP)),
          ];

          let dadosParaPremioEquipe = [];

          for (const equipe of dadosComissaoEquipe) {
            const dadosDoCalculoAnterior = this.dadosDoCalculoEquipe
              .filter(e => e.nome.split("-")[0] === equipe)
              .map(e => e.calculo);

            let porc = 0;
            let premio = 0;

            dadosDoCalculoAnterior.map(e => {
              e.map(s => {
                if (s.vl_premioCurr > 0) {
                  porc = Number(s.fx_inicial.split("%")[0]);
                  premio = s.vl_premioCurr;
                }
              });
            });

            if (porc >= 100) {
              dadosParaPremioEquipe.push({
                premio,
                porc,

                idEquipe: equipe,
                dadosComissao: dadosComissaoEquipeOld.filter(
                  e => e.ZA8_IDEQP === equipe,
                ),
              });
            }
          }

          await this.calculoEquipeProspeccao(dadosParaPremioEquipe);
        }
      }

      this.showSkelekton = false;
      this.showDados = true;

      this.totalGeralPremio = this.totalGeralPremio.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      this.totalPremioGeral =
        this.totalPremioIndividual +
        this.totalPremioOpGrade +
        this.totalPremioEquipe +
        this.totalRefinamento;

      // não atualizar calculo do trimestre fechado
      url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const premioTrimestre = await axios.post(url, {
        FIELDS: "ZAA_FECHAD",
        WHERE: `ZAA_ANOTRI='${this.anoEscolhido}${this.trimestreEscolhido}'
            AND ZAA_CODVEN='${this.vendedor}'`,
        TABELA: "ZAA",
      });

      if (premioTrimestre.data[0]?.ZAA_FECHAD === "S") {
        this.showSkelekton = false;
        this.showDados = true;
        return;
      }

      //aqui vou gravar a tabela auxiliar para o total da premiação.
      const dadosTabAux = {
        ZAA_ANOTRI: `${this.anoEscolhido}${this.trimestreEscolhido}`,
        ZAA_CODVEN: profissional[0].ZA2_CODVEN,
        ZAA_AREA: "",
        ZAA_LOJA: profissional[0].ZA2_LOJA,
        ZAA_PROFIS: profissional[0].ZA9_DESCRI
          ? profissional[0].ZA9_DESCRI.substring(0, 80)
          : "",
        ZAA_VALOR: this.totalPremioGeral,
      };
      const urlAux = `${process.env.VUE_APP_BASE_API_URL}/addAuxPremio`;
      await axios.post(urlAux, dadosTabAux);

      this.showSkelekton = false;
      this.showDados = true;
    },

    async premioMktAgendamento() {
      this.mktAgendamento = true;
      this.metaIndividualAgendamento = 0;
      this.metaAteIndividualAgendamento = 0;
      this.calculoDadosComissaoAgendamento = [];

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasAgendamento`;

      this.titlePremioCalc = "Buscando Agendamentos...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        tipo: "A",
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      console.log(metricas);

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "F",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaIndividualAgendamento = 0;
      this.metaAteIndividualAgendamento = 0;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.meta * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.meta * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualAgendamento <= 0) {
          this.metaAteIndividualAgendamento = valorInicial - 1;
        }

        let range = Number(
          ((metricas.data.agendamentos * 100) / metricas.data.meta).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.agendamentos <= valorFinal &&
          metricas.data.agendamentos >= valorInicial
        ) {
          let valorPremio = Number(
            ((e.ZXG_VLPRM * e.ZA3_PERC) / 100).toFixed(2),
          );

          valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

          if (e.ZA3_PERC !== 100) {
            valorPremio = 50 - (valorPremio % 50) + valorPremio;
          }

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: metricas.data.agendamentos,
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          calculoDados.push({
            vl_vendido: metricas.data.agendamentos,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: "-",
          });
        }
      });

      this.metaIndividualAgendamento = Math.round(metricas.data.meta);

      this.calculoDadosComissaoAgendamento = calculoDados;

      this.$toast.success("Prêmio Agendamento Leads gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async premioMktComparecimento() {
      this.mktComparecimento = true;
      this.metaIndividualComparecimento = 0;
      this.metaAteIndividualComparecimento = 0;
      this.calculoDadosComissaoComparecimento = [];

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasAgendamento`;

      this.titlePremioCalc = "Buscando Agendamentos...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        tipo: "C",
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      console.log(metricas);

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "G",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.meta * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.meta * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualComparecimento <= 0) {
          this.metaAteIndividualComparecimento = valorInicial - 1;
        }

        let range = Number(
          ((metricas.data.agendamentos * 100) / metricas.data.meta).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.agendamentos <= valorFinal &&
          metricas.data.agendamentos >= valorInicial
        ) {
          let valorPremio = Number(
            ((e.ZXG_VLPRM * e.ZA3_PERC) / 100).toFixed(2),
          );

          valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

          if (e.ZA3_PERC !== 100) {
            valorPremio = 50 - (valorPremio % 50) + valorPremio;
          }

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: metricas.data.agendamentos,
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          calculoDados.push({
            vl_vendido: metricas.data.agendamentos,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: "-",
          });
        }
      });

      this.metaIndividualComparecimento = Math.round(metricas.data.meta);

      this.calculoDadosComissaoComparecimento = calculoDados;

      this.$toast.success(
        "Prêmio Comparecimento Leads agenda gerado com sucesso!",
        {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        },
      );
    },

    async premioProspeIndica() {
      this.prospeccaoIndicacao = true;
      this.metaIndividualIndicacao = 0;
      this.metaAteIndividualIndicacao = 0;
      this.calculoDadosComissaoIndicacao = [];

      this.itemsDetalhamento = [];
      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasIndicacao`;

      this.titlePremioCalc = "Buscando faturamento...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "X",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/metasIndicacao`;

      const metasIndicacao = await axios.post(url, {
        tipo: "X",
        vendedor: this.vendedor,
        trimestre: this.trimestreEscolhido,
        ano: this.anoEscolhido,
      });

      if (!metasIndicacao || metasIndicacao.status !== 200) {
        console.log("erro");
        return;
      }

      let totalRealMeta = 0;

      metasIndicacao.data.map(e => {
        totalRealMeta += Number(
          ((e.dados.ZXE_META * e.dados.ZA3_PERC) / 100).toFixed(2),
        );
      });

      console.log(totalRealMeta);

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualIndicacao <= 0) {
          this.metaAteIndividualIndicacao = valorInicial - 1;
        }

        let range = Number(
          (
            (metricas.data.totalIndicacao * 100) /
            metricas.data.totalPainel
          ).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.totalIndicacao <= valorFinal &&
          metricas.data.totalIndicacao >= valorInicial
        ) {
          let valorPremio = Number(
            ((totalRealMeta * e.ZA3_PERC) / 100).toFixed(2),
          );

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: metricas.data.totalIndicacao,
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          calculoDados.push({
            vl_vendido: metricas.data.totalIndicacao,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: "-",
          });
        }
      });

      this.metaIndividualIndicacao = Math.round(metricas.data.totalPainel);

      this.calculoDadosComissaoIndicacao = calculoDados;

      this.$toast.success(
        "Prêmio prospecção Indicação Médica gerado com sucesso!",
        {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        },
      );
    },
    async premioProspeVisita() {
      this.prospeccaoVisitacao = true;

      this.itemsDetalhamento = [];
      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;

      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasVisitacao`;

      this.titlePremioCalc = "Buscando faturamento...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestreEscolhido,
        vendedor: this.vendedor,
        tipo: "V",
      });

      if (!dadosPremio || dadosPremio.status !== 200) {
        console.log("erro");
        return;
      }

      url = `${process.env.VUE_APP_BASE_API_URL}/metasIndicacao`;

      const metasIndicacao = await axios.post(url, {
        tipo: "V",
        vendedor: this.vendedor,
        trimestre: this.trimestreEscolhido,
        ano: this.anoEscolhido,
      });

      if (!metasIndicacao || metasIndicacao.status !== 200) {
        console.log("erro");
        return;
      }

      let totalRealMeta = 0;

      metasIndicacao.data.map(e => {
        totalRealMeta += Number(
          ((e.dados.ZXE_META * e.dados.ZA3_PERC) / 100).toFixed(2),
        );
      });

      console.log(totalRealMeta);

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDINI) / 100).toFixed(2),
        );

        let valorFinal = Math.round(
          ((metricas.data.totalPainel * e.ZA3_INDFIM) / 100).toFixed(2),
        );

        valorFinal -= 1;

        if (this.metaAteIndividualVisitacao <= 0) {
          this.metaAteIndividualVisitacao = valorInicial - 1;
        }

        let range = Number(
          (
            (metricas.data.totalVisitacao * 100) /
            metricas.data.totalPainel
          ).toFixed(2),
        );

        let tagMax = "";
        if (range > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.totalVisitacao <= valorFinal &&
          metricas.data.totalVisitacao >= valorInicial
        ) {
          let valorPremio = Number(
            ((totalRealMeta * e.ZA3_PERC) / 100).toFixed(2),
          );

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: metricas.data.totalVisitacao,
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          calculoDados.push({
            vl_vendido: metricas.data.totalVisitacao,
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: valorInicial,
            vl_final: valorFinal,
            vl_premio: "-",
          });
        }
      });

      this.metaIndividualVisitacao = Math.round(metricas.data.totalPainel);

      this.calculoDadosComissaoVisitacao = calculoDados;

      this.$toast.success("Prêmio Visitação médcia gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async premioIndividual(vendedor) {
      this.itemsDetalhamento = [];
      this.andamento = 0;
      this.numberofEquipes = 0;
      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;

      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;
      const headersWidth = [
        "VENDEDOR",
        "INDICADOR",
        "PROSPECTOR",
        "DESC_CF",
        "NOME_CLIENTE",
      ];

      let dados = {};
      let url = ``;

      this.titlePremioCalc = "Buscando faturamento...";

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor && e.ZA2_TIPO === "I",
      );

      let anomes = "";

      if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
        anomes = `'${this.anoEscolhido}01','${this.anoEscolhido}02','${this.anoEscolhido}03'`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
        anomes = `'${this.anoEscolhido}04','${this.anoEscolhido}05','${this.anoEscolhido}06'`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
        anomes = `'${this.anoEscolhido}07','${this.anoEscolhido}08','${this.anoEscolhido}09'`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
        anomes = `'${this.anoEscolhido}10','${this.anoEscolhido}11','${this.anoEscolhido}12'`;
      }

      if (profissional[0].ZA2_PROFIS === "000015") {
        if (this.switchDetalhe) {
          dados = {
            FIELDS: `*`,
            WHERE: `CHAVE_VENDEDOR='${vendedor.ZA2_CODVEN}'
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        } else {
          dados = {
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
            WHERE: `CHAVE_VENDEDOR='${vendedor.ZA2_CODVEN}'
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        }
      } else if (profissional[0].ZA2_PROFIS === "000016") {
        if (this.switchDetalhe) {
          dados = {
            FIELDS: `*`,
            WHERE: `CHAVE_VENDEDOR='002828'
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        } else {
          dados = {
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
            WHERE: `CHAVE_VENDEDOR='002828'
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        }
      } else if (profissional[0].ZA2_PROFIS === "000017") {
        if (this.switchDetalhe) {
          dados = {
            FIELDS: `*`,
            WHERE: `LINHA='CIRURGICA'
                        AND ANOMES IN (${anomes})
                        AND LEFT(VENDEDOR,6)='${profissional[0].ZA2_SEGVEN}'
                        AND LEFT(DESC_SUBGRUPO_PRODUTO,4) IN ('0007','0023')
                        AND APAC<> 'S' `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        } else {
          dados = {
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
            WHERE: `LINHA='CIRURGICA'
                        AND ANOMES IN (${anomes})
                        AND LEFT(VENDEDOR,6)='${profissional[0].ZA2_SEGVEN}'
                        AND LEFT(DESC_SUBGRUPO_PRODUTO,4) IN ('0007','0023')
                        AND APAC<> 'S' `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        }
      } else if (profissional[0].ZA2_PROFIS === "000006") {
        if (this.switchDetalhe) {
          dados = {
            FIELDS: `*`,
            WHERE: `LEFT(CANAL,6)='000002'
                             AND ANOMES IN (${anomes}) `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        } else {
          dados = {
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
            WHERE: `LEFT(CANAL,6)='000002'
                             AND ANOMES IN (${anomes}) `,
            TABELA: "DB_V_RECEITAS_BI",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        }
      } else {
        if (this.switchDetalhe) {
          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: ``,
            WHERE: `CHAVE_VENDEDOR='${vendedor.ZA2_CODVEN}'
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' ORDER BY DATA_EMISSAO DESC`,
            TABELA: `DB_V_RECEITAS_BI`,
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        } else {
          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
            WHERE: ` CHAVE_VENDEDOR='${vendedor.ZA2_CODVEN}'
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' `,
            TABELA: `DB_V_RECEITAS_BI`,
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.itemsDetalhamento = res.data;
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        }
      }

      if (this.switchDetalhe) {
        if (this.itemsDetalhamento[0]) {
          this.numberLines = this.itemsDetalhamento.length;
          const headers = Object.keys(this.itemsDetalhamento[0]).map(e => {
            if (headersWidth.includes(e)) {
              return {
                text: e,
                align: "start",
                sortable: true,
                value: e,
                width: "500",
              };
            } else {
              return {
                text: e,
                align: "start",
                sortable: true,
                value: e,
              };
            }
          });
          this.headersRelatExcel = Object.keys(this.itemsDetalhamento[0]).map(
            e => {
              return {
                label: e,
                field: e,
              };
            },
          );
          this.itemsRelatExcel = this.itemsDetalhamento;
          this.nameRelatExcel = vendedor.A3_NOME.substring(0, 15);
          this.headersDetalhamento = headers;
          const valorTotal = this.itemsDetalhamento.reduce(
            (total, item) => total + item.FATURAMENTO_LIQUIDO,
            0,
          );
          this.valorTotalDetalhamento = valorTotal.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        } else {
          this.dialogInfo = true;
          this.tituloCard = "Erro";
          this.mensagem =
            "Não achei faturamento para o vendedor no período selecionado!";
          this.showSkelekton = false;
          return;
        }
      } else {
        if (this.itemsDetalhamento[0].FATURAMENTO_LIQUIDO) {
          this.valorTotalDetalhamento = this.itemsDetalhamento[0].FATURAMENTO_LIQUIDO.toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            },
          );
        } else {
          this.dialogInfo = true;
          this.tituloCard = "Erro";
          this.mensagem =
            "Não achei faturamento para o vendedor no período selecionado!";
          this.showSkelekton = false;
          return;
        }
      }

      this.titlePremioCalc = "Calculando prêmio individual...";

      console.log(Number(this.trimestreEscolhido));

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZA2_TIPO,ZA3_PBASE,ZXG_LOJA,ZA2_TPMETA,ZXG_HRTRAB,ZXG_QTDP,ZXG_PESO`,
        INNER: ` INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
          this.trimestreEscolhido,
        )}'
                 INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA3.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID `,
        WHERE: `ZA2_CODVEN='${vendedor.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='I'`,
        TABELA: `ZA2`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      let dadosComissao = [];
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      await axios
        .post(url, dados)
        .then(res => {
          dadosComissao = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      let calculoDados = [];
      let meta = 0;
      this.metaIndividual = null;
      let valorTotalVendido = parseFloat(
        this.valorTotalDetalhamento
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", "."),
      );

      let tri = "";
      if (dadosComissao.length >= 1) {
        meta = 0;
        if (dtInicial === `${this.anoEscolhido}0101`) {
          tri = `${this.anoEscolhido}01`;
        }
        if (dtInicial === `${this.anoEscolhido}0401`) {
          tri = `${this.anoEscolhido}02`;
        }
        if (dtInicial === `${this.anoEscolhido}0701`) {
          tri = `${this.anoEscolhido}03`;
        }
        if (dtInicial === `${this.anoEscolhido}1001`) {
          tri = `${this.anoEscolhido}04`;
        }

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZXE_META`,
          WHERE: `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' `,
          TABELA: `ZXE`,
        };

        if (
          dadosComissao[0].ZA2_TPMETA.trim() === "FN" &&
          dadosComissao[0].ZXG_HRTRAB > 0
        ) {
          dados.WHERE = `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' AND ZXE_HRTRAB='${dadosComissao[0].ZXG_HRTRAB}'`;
        }

        if (vendedor.ZA2_CODVEN === "002344") {
          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: `ZXE_META`,
            WHERE: `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='AS' AND ZXE_ANOTRI='${tri}'`,
            TABELA: `ZXE`,
          };
        }

        const valor = await axios.post(url, dados);
        if (dadosComissao[0].ZXG_QTDP < 1) {
          dadosComissao[0].ZXG_QTDP = 1;
        }

        if (vendedor.ZA2_CODVEN === "002344" && tri === "202304") {
          valor.data[0].ZXE_META = 20000;
        }

        let mt = Number(
          ((valor.data[0].ZXE_META * 3) / dadosComissao[0].ZXG_QTDP).toFixed(2),
        );

        meta = mt;

        this.metaIndividual = meta.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        this.metaAteIndividual = 0;

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(((meta * e.ZA3_INDINI) / 100).toFixed(2));

          if (this.metaAteIndividual <= 0) {
            this.metaAteIndividual = (valorInicial - 0.01).toLocaleString(
              "pt-BR",
              {
                style: "currency",
                currency: "BRL",
              },
            );
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(((meta * proximoRange) / 100 - 0.01).toFixed(2));

          let valorMaxPorc = (valorTotalVendido * 100) / meta;

          let tagMax = "";
          if (valorMaxPorc > e.ZA2_LIMITE) {
            tagMax = "Excedeu o limite de 120%";
          }

          if (
            valorTotalVendido <= valorFinal &&
            valorTotalVendido >= valorInicial
          ) {
            let valorBase = Number(((meta * e.ZA3_PBASE) / 100).toFixed(2));

            let valorPremio = Number(
              ((valorBase * e.ZA3_PERC) / 100).toFixed(2),
            );

            valorPremio = 50 - (valorPremio % 50) + valorPremio;

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            if (e.ZXG_PESO < 100) {
              valorPremio = 50 - (valorPremio % 50) + valorPremio;
            }

            this.totalGeralPremio += valorPremio;
            this.totalPremioIndividual += valorPremio;

            calculoDados.push({
              vl_vendido: this.valorTotalDetalhamento,
              fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: valorPremio.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
            });
          } else {
            calculoDados.push({
              vl_vendido: this.valorTotalDetalhamento,
              fx_inicial: `${e.ZA3_INDINI}%`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: "-",
            });
          }
        });
      } else {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei dados para o cálculo do prêmio INDIVIDUAL!";
      }

      this.calculoDadosComissao = calculoDados;

      this.$toast.success("Prêmio individual gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async premioOpgrade(vendedor) {
      const datas = this.trimestre.split("-");
      const dtInicial = `${this.anoEscolhido}${datas[0]}`;

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor && e.ZA2_TIPO === "O",
      );

      const codFonos = profissional[0].ZA2_FONOS.split(";");

      let fonos = ``;

      codFonos.map(e => {
        fonos += `'${e}',`;
      });

      fonos = fonos.substring(0, fonos.length - 1);

      let anomes = "";

      if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
        anomes = `'${this.anoEscolhido}01','${this.anoEscolhido}02','${this.anoEscolhido}03'`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
        anomes = `'${this.anoEscolhido}04','${this.anoEscolhido}05','${this.anoEscolhido}06'`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
        anomes = `'${this.anoEscolhido}07','${this.anoEscolhido}08','${this.anoEscolhido}09'`;
      }
      if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
        anomes = `'${this.anoEscolhido}10','${this.anoEscolhido}11','${this.anoEscolhido}12'`;
      }

      let dados = {};

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ZA3_INDINI,ZA3_INDFIM,ZA3_TIPO,ZA3_PERC,ZA2_LIMITE,ZA2_TIPO,ZA3_PBASE,ZXG_LOJA,ZA2_TPMETA,ZXG_HRTRAB,ZXG_QTDP,ZXG_PESO`,
        INNER: `  INNER JOIN ZXG010 ZXG WITH(NOLOCK) ON ZXG.D_E_L_E_T_=' ' AND ZXG_IDZA2=ZA2_ID AND ZXG_TRIMES='${Number(
          this.trimestreEscolhido,
        )}'
                  INNER JOIN ZA3010 ZA3 WITH(NOLOCK) ON ZA3.D_E_L_E_T_=' ' AND ZA3_IDREG=ZXG_ID `,
        WHERE: `ZA2_CODVEN='${vendedor.ZA2_CODVEN}' AND ZA2_ANO='${this.anoEscolhido}' AND ZA2_TIPO='O'`,
        TABELA: `ZA2`,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      let dadosComissao = [];
      this.calculoDadosComissaoOpGrade = [];
      this.metaAteIndividualOpGrade = null;
      this.labelDivisaoOpgrade = null;

      await axios
        .post(url, dados)
        .then(res => {
          dadosComissao = res.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      if (profissional[0].ZA2_PROFIS === "000016") {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
          WHERE: ` LEFT(AREA,6)='000004'
                            AND PLANO_SAUDE<>'1'
                            AND APAC<>'S'
                            AND ANOMES IN (${anomes})
                            AND RELATORIO_BI<>'2'
                            AND LEFT(CANAL,6)='000004' `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      } else {
        dados = {
          FIELDS: `SUM(FATURAMENTO_LIQUIDO) FATURAMENTO_LIQUIDO`,
          WHERE: ` LEFT(FILIAL,4)='${dadosComissao[0].ZXG_LOJA}'
                            AND LINHA='AUDITIVA'
                            AND PLANO_SAUDE<>'1'
                            AND APAC<>'S'
                            AND ANOMES IN (${anomes})
                            AND RELATORIO_BI<>'2'
                            AND CHAVE_VENDEDOR  IN (${fonos})
                            AND LEFT(CANAL,6)='000004' `,
          TABELA: "DB_V_RECEITAS_BI",
        };
      }

      const itemsDetalhamento = await axios.post(url, dados);

      if (itemsDetalhamento.data[0].FATURAMENTO_LIQUIDO) {
        this.valorTotalDetalhamentoOpGrade = itemsDetalhamento.data[0].FATURAMENTO_LIQUIDO.toLocaleString(
          "pt-BR",
          {
            style: "currency",
            currency: "BRL",
          },
        );
      } else {
        this.valorTotalDetalhamentoOpGrade = "R$ 0,00";
      }

      let calculoDados = [];
      let meta = 0;
      this.metaIndividualOpGrade = null;
      let valorTotalVendido = parseFloat(
        this.valorTotalDetalhamentoOpGrade
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", "."),
      );

      let tri = "";

      if (dadosComissao.length >= 1) {
        meta = 0;
        if (dtInicial === `${this.anoEscolhido}0101`) {
          tri = `${this.anoEscolhido}01`;
        }
        if (dtInicial === `${this.anoEscolhido}0401`) {
          tri = `${this.anoEscolhido}02`;
        }
        if (dtInicial === `${this.anoEscolhido}0701`) {
          tri = `${this.anoEscolhido}03`;
        }
        if (dtInicial === `${this.anoEscolhido}1001`) {
          tri = `${this.anoEscolhido}04`;
        }

        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `ZXE_META`,
          WHERE: `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' `,
          TABELA: `ZXE`,
        };

        if (
          dadosComissao[0].ZA2_TPMETA.trim() === "FN" &&
          dadosComissao[0].ZXG_HRTRAB > 0
        ) {
          dados.WHERE = `ZXE_LOJA='${dadosComissao[0].ZXG_LOJA}' AND ZXE_TPCALC='${dadosComissao[0].ZA2_TPMETA}' AND ZXE_ANOTRI='${tri}' AND ZXE_HRTRAB='${dadosComissao[0].ZXG_HRTRAB}' `;
        }

        const valor = await axios.post(url, dados);

        console.log("valor da meta===> ", valor.data);

        if (dadosComissao[0].ZXG_QTDP < 1) {
          dadosComissao[0].ZXG_QTDP = 1;
        }
        let mt = Number(
          ((valor.data[0].ZXE_META * 3) / dadosComissao[0].ZXG_QTDP).toFixed(2),
        );

        meta = mt;

        this.metaIndividualOpGrade = meta.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        this.metaAteIndividualOpGrade = 0;

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(((meta * e.ZA3_INDINI) / 100).toFixed(2));

          if (this.metaAteIndividualOpGrade <= 0) {
            this.metaAteIndividualOpGrade = (
              valorInicial - 0.01
            ).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(((meta * proximoRange) / 100 - 0.01).toFixed(2));

          let valorMaxPorc = (valorTotalVendido * 100) / meta;

          let tagMax = "";
          if (valorMaxPorc > e.ZA2_LIMITE) {
            tagMax = "Excedeu o limite de 120%";
          }

          if (
            valorTotalVendido <= valorFinal &&
            valorTotalVendido >= valorInicial
          ) {
            let valorBase = Number(((meta * e.ZA3_PBASE) / 100).toFixed(2));

            let valorPremio = Number(
              ((valorBase * e.ZA3_PERC) / 100).toFixed(2),
            );

            valorPremio = 50 - (valorPremio % 50) + valorPremio;

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            if (e.ZXG_PESO < 100) {
              valorPremio = 50 - (valorPremio % 50) + valorPremio;
            }

            this.totalGeralPremioOpGrade += valorPremio;
            this.totalPremioOpGrade += valorPremio;

            calculoDados.push({
              vl_vendido: this.valorTotalDetalhamentoOpGrade,
              fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: valorPremio.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
            });
          } else {
            calculoDados.push({
              vl_vendido: this.valorTotalDetalhamentoOpGrade,
              fx_inicial: `${e.ZA3_INDINI}%`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: "-",
            });
          }
        });
      } else {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei dados para o cálculo do prêmio INDIVIDUAL!";
      }

      this.calculoDadosComissaoOpGrade = calculoDados;

      this.$toast.success("Prêmio OpGrade gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },

    async calculoEquipeProspeccao(dados) {
      if (dados.length <= 0) return;

      console.log("Calculo de premiação prospector");

      const datas = this.trimestre.split("-");

      const dtInicial = `${this.anoEscolhido}${datas[0]}`;

      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;
      let dadosUrl = {};
      let equipes = [];
      dados.forEach(e => {
        if (!equipes.includes(e.idEquipe)) {
          equipes.push(e.idEquipe);
        }
      });

      let dadosVendedor = dados;

      let dadosVendedoresEquipe = [];

      this.numberofEquipes = equipes.length;

      this.andamento = 0;

      const identador = 100 / equipes.length;

      for (const equipe of equipes) {
        dadosUrl = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "ZA7_CODVEN,ZA5_NOME,ZA7_IDCAB,ZA5_LOJA,ZA5_TIPO",
          INNER: `INNER JOIN ZA5010 ZA5 WITH(NOLOCK) ON (ZA5_COD=ZA7_IDCAB AND ZA5_ANO='${this.anoEscolhido}' AND ZA5.D_E_L_E_T_=' ')`,
          WHERE: `ZA7_IDCAB='${equipe}' AND ZA7.D_E_L_E_T_=' '`,
          TABELA: "ZA7",
        };

        await axios
          .post(url, dadosUrl)
          .then(async res => {
            let vendedores = "";

            res.data.forEach(e => {
              if (vendedores === "") {
                vendedores = `'${e.ZA7_CODVEN}'`;
              } else {
                vendedores += `,'${e.ZA7_CODVEN}'`;
              }
            });
            let meta = 0;
            let tri = "";
            if (dtInicial === `${this.anoEscolhido}0101`) {
              tri = `${this.anoEscolhido}01`;
            }
            if (dtInicial === `${this.anoEscolhido}0401`) {
              tri = `${this.anoEscolhido}02`;
            }
            if (dtInicial === `${this.anoEscolhido}0701`) {
              tri = `${this.anoEscolhido}03`;
            }
            if (dtInicial === `${this.anoEscolhido}1001`) {
              tri = `${this.anoEscolhido}04`;
            }

            dados = {
              EMPRESA: "01",
              FILIAL: "0101",
              FIELDS: `ZXE_META`,
              WHERE: `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='UN' AND ZXE_ANOTRI='${tri}'`,
              TABELA: `ZXE`,
            };

            const valor = await axios.post(url, dados);

            let mt = Number((valor.data[0].ZXE_META * 3).toFixed(2));

            meta = mt;

            dadosVendedoresEquipe.push({
              idEquipe: res.data[0].ZA7_IDCAB,
              nome: res.data[0].ZA5_NOME,
              vendedores,
              meta,
              loja: res.data[0].ZA5_LOJA,
            });
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }

      if (dadosVendedoresEquipe.length <= 0) {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei vendedores para o cálculo do prêmio EQUIPE!";
        return;
      }

      this.totalGeralEquipes = 0;
      for (const equipe of dadosVendedoresEquipe) {
        let detalhamentoFatVendedores = [];

        this.titlePremioCalc = `Calculando faturamento ${equipe.nome}`;

        let urlNode = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

        let anomes = "";

        if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
          anomes = `'${this.anoEscolhido}01','${this.anoEscolhido}02','${this.anoEscolhido}03'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
          anomes = `'${this.anoEscolhido}04','${this.anoEscolhido}05','${this.anoEscolhido}06'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
          anomes = `'${this.anoEscolhido}07','${this.anoEscolhido}08','${this.anoEscolhido}09'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
          anomes = `'${this.anoEscolhido}10','${this.anoEscolhido}11','${this.anoEscolhido}12'`;
        }

        if (equipe.idEquipe === "000034") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO, 'AREA 4 ' VENDEDOR FROM DB_V_RECEITAS_BI WHERE ANOMES IN (${anomes}) AND LEFT(AREA,6)='000004' AND LEFT(VENDEDOR,6) <> '002828'`,
          };
        } else {
          dadosUrl = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: "VENDEDOR,INDICADOR,SUM(FATURAMENTO_LIQUIDO) FATURAMENTO",
            WHERE: `CHAVE_VENDEDOR IN (${equipe.vendedores})
                               AND PLANO_SAUDE<>'1'
                               AND APAC<>'S'
                               AND ANOMES IN (${anomes})
                               AND RELATORIO_BI<>'2' GROUP BY VENDEDOR,INDICADOR`,
            TABELA: "DB_V_RECEITAS_BI",
          };
        }

        const faturamentoVendedores = await axios
          .post(urlNode, dadosUrl)
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        const totalFaturamentoVendedores = faturamentoVendedores.data.reduce(
          (total, item) => total + item.FATURAMENTO,
          0,
        );

        this.totalGeralEquipes += totalFaturamentoVendedores;

        detalhamentoFatVendedores = faturamentoVendedores.data.map(e => {
          return {
            VENDEDOR: e.VENDEDOR,
            FATURAMENTO: e.FATURAMENTO.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
          };
        });

        const dadosComissao = dadosVendedor.filter(
          e => e.idEquipe === equipe.idEquipe,
        )[0].dadosComissao;
        let calculoDados = [];
        let metaAteEquipe = 0;

        this.labelDivisaoEquipe = null;

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(
            ((equipe.meta * e.ZA3_INDINI) / 100).toFixed(2),
          );

          if (metaAteEquipe <= 0) {
            metaAteEquipe = (valorInicial - 0.01).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(
            ((equipe.meta * proximoRange) / 100 - 0.01).toFixed(2),
          );

          let valorMaxPorc = (totalFaturamentoVendedores * 100) / equipe.meta;

          let tagMax = "";
          if (valorMaxPorc > e.ZA2_LIMITE) {
            tagMax = "Excedeu o limite de 120%";
          }

          if (
            totalFaturamentoVendedores <= valorFinal &&
            totalFaturamentoVendedores >= valorInicial
          ) {
            let valorAlcancadoPremioIndividual = dadosVendedor.filter(
              d => d.idEquipe === e.ZA8_IDEQP,
            )[0].premio;

            let valorPremio = Number(
              ((valorAlcancadoPremioIndividual * e.ZA3_PERC) / 100).toFixed(2),
            );

            valorPremio = 50 - (valorPremio % 50) + valorPremio;

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            if (e.ZXG_PESO < 100) {
              valorPremio = 50 - (valorPremio % 50) + valorPremio;
            }

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            this.totalGeralPremio += valorPremio;
            this.totalPremioEquipe += valorPremio;

            calculoDados.push({
              vl_vendido: totalFaturamentoVendedores.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              vl_vendidoCurr: totalFaturamentoVendedores,
              fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: valorPremio.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premioCurr: valorPremio,
            });
          } else {
            calculoDados.push({
              vl_vendido: totalFaturamentoVendedores.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              vl_vendidoCurr: totalFaturamentoVendedores,
              fx_inicial: `${e.ZA3_INDINI}%`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: "-",
              vl_premioCurr: 0,
            });
          }
        });

        this.dadosDoCalculoEquipe.push({
          nome: equipe.nome,
          meta: equipe.meta.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          detalhamento: detalhamentoFatVendedores,
          totalFaturamento: totalFaturamentoVendedores,
          metaAte: metaAteEquipe,
          calculo: calculoDados,
          loja: equipe.loja,
        });

        this.andamento += identador;
      }

      this.calculoDadosComissaoEquipe = [];

      this.$toast.success("Prêmio de equipe gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async calculoEquipe(dados, dtInicial) {
      let url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;
      let dadosUrl = {};
      let equipes = [];
      let dadosVendedor = dados;
      dados.forEach(e => {
        if (!equipes.includes(e.ZA8_IDEQP)) {
          equipes.push(e.ZA8_IDEQP);
        }
      });

      let dadosVendedoresEquipe = [];

      this.numberofEquipes = equipes.length;

      this.andamento = 0;

      const identador = 100 / equipes.length;

      for await (const equipe of equipes) {
        dadosUrl = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "ZA7_CODVEN,ZA5_COD,ZA5_NOME,ZA7_IDCAB,ZA5_LOJA,ZA5_TIPO",
          INNER: `INNER JOIN ZA5010 ZA5 WITH(NOLOCK) ON (ZA5_COD=ZA7_IDCAB AND ZA5_ANO='${this.anoEscolhido}' AND ZA5.D_E_L_E_T_=' ')`,
          WHERE: `ZA7_IDCAB='${equipe}' AND ZA7.D_E_L_E_T_=' '`,
          TABELA: "ZA7",
        };

        await axios
          .post(url, dadosUrl)
          .then(async res => {
            if (res.data.length > 0) {
              let vendedores = "";

              res.data.forEach(e => {
                if (vendedores === "") {
                  vendedores = `'${e.ZA7_CODVEN}'`;
                } else {
                  vendedores += `,'${e.ZA7_CODVEN}'`;
                }
              });
              let meta = 0;
              let tri = "";
              if (dtInicial === `${this.anoEscolhido}0101`) {
                tri = `${this.anoEscolhido}01`;
              }
              if (dtInicial === `${this.anoEscolhido}0401`) {
                tri = `${this.anoEscolhido}02`;
              }
              if (dtInicial === `${this.anoEscolhido}0701`) {
                tri = `${this.anoEscolhido}03`;
              }
              if (dtInicial === `${this.anoEscolhido}1001`) {
                tri = `${this.anoEscolhido}04`;
              }

              dados = {
                EMPRESA: "01",
                FILIAL: "0101",
                FIELDS: `ZXE_META`,
                WHERE: `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='${res.data[0].ZA5_TIPO}' AND ZXE_ANOTRI='${tri}'`,
                TABELA: `ZXE`,
              };

              if (
                (this.vendedor === "002163" || this.vendedor === "002344") &&
                res.data[0].ZA7_IDCAB === "000034"
              ) {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='AS' AND ZXE_ANOTRI='${tri}'`;
              }

              if (res.data[0].ZA5_TIPO.trim() === "PF") {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='FN' AND ZXE_HRTRAB='44' AND ZXE_ANOTRI='${tri}'`;
              }

              if (res.data[0].ZA5_TIPO.trim() === "AD") {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='UN' AND ZXE_TIPO='FM' AND ZXE_ANOTRI='${tri}'`;
              }

              if (
                this.vendedor === "003785" &&
                res.data[0].ZA7_IDCAB === "000050"
              ) {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='FN' AND ZXE_HRTRAB='44' AND ZXE_ANOTRI='${tri}'`;
              }

              if (
                (this.vendedor === "002344" || this.vendedor === "002974") &&
                res.data[0].ZA7_IDCAB === "000034"
              ) {
                dados.WHERE = `ZXE_LOJA='${res.data[0].ZA5_LOJA}' AND ZXE_TPCALC='FN' AND ZXE_HRTRAB='44' AND ZXE_ANOTRI='${tri}'`;
              }

              let valor = await axios.post(url, dados);

              let mt = 0;

              if (valor.data.length > 0) {
                mt = Number((valor.data[0].ZXE_META * 3).toFixed(2));
              }

              meta = mt;

              dadosVendedoresEquipe.push({
                idEquipe: res.data[0].ZA7_IDCAB,
                nome: res.data[0].ZA5_NOME,
                vendedores,
                tipo: res.data[0]?.ZA5_TIPO.trim(),
                meta,
                loja: res.data[0]?.ZA5_LOJA.trim(),
              });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });
      }

      if (dadosVendedoresEquipe.length <= 0) {
        this.dialogInfo = true;
        this.tituloCard = "Erro";
        this.mensagem = "Não achei vendedores para o cálculo do prêmio EQUIPE!";
        return;
      }

      this.totalGeralEquipes = 0;
      for (const equipe of dadosVendedoresEquipe) {
        let detalhamentoFatVendedores = [];

        this.titlePremioCalc = `Calculando faturamento ${equipe.nome}`;

        let profissional = this.dadosVendedoresGeral.filter(
          e => e.ZA2_CODVEN === this.vendedor && e.ZA2_TIPO === "E",
        );

        let urlNode = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

        let anomes = "";

        if (this.trimestre.split("-")[0].substr(0, 2) == "01") {
          anomes = `'${this.anoEscolhido}01','${this.anoEscolhido}02','${this.anoEscolhido}03'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "04") {
          anomes = `'${this.anoEscolhido}04','${this.anoEscolhido}05','${this.anoEscolhido}06'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "07") {
          anomes = `'${this.anoEscolhido}07','${this.anoEscolhido}08','${this.anoEscolhido}09'`;
        }
        if (this.trimestre.split("-")[0].substr(0, 2) == "10") {
          anomes = `'${this.anoEscolhido}10','${this.anoEscolhido}11','${this.anoEscolhido}12'`;
        }

        const equipesCirur = ["CPA", "CGO", "CBR", "CRO"];

        dadosUrl = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: "VENDEDOR,SUM(FATURAMENTO_LIQUIDO) FATURAMENTO",
          WHERE: `CHAVE_VENDEDOR IN (${equipe.vendedores})
                             AND PLANO_SAUDE<>'1'
                             AND APAC<>'S'
                             AND ANOMES IN (${anomes})
                             AND RELATORIO_BI<>'2' GROUP BY VENDEDOR`,
          TABELA: "DB_V_RECEITAS_BI",
        };

        //EQUIPE DE FONOS AREA 4
        if (equipe.tipo === "PF" && equipe.loja === "1005") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO, 'EQUIPE FONO AREA 4 ' VENDEDOR FROM DB_V_RECEITAS_BI WHERE ANOMES IN (${anomes}) AND LEFT(AREA,6)='000004' AND LEFT(VENDEDOR,6) <> '002828'`,
          };
        }

        //ASSISTENTE AREA 4
        if (equipe.tipo === "AS" && equipe.loja === "1005") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO, 'ASSISTENTE AREA 4' VENDEDOR FROM DB_V_RECEITAS_BI WHERE ANOMES IN (${anomes}) AND CHAVE_VENDEDOR IN (${equipe.vendedores})`,
          };
        }

        //AREA 4
        if (equipe.tipo === "UN" && equipe.loja === "1005") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO, 'AREA 4 ' VENDEDOR FROM DB_V_RECEITAS_BI WHERE ANOMES IN (${anomes}) AND LEFT(AREA,6)='000004'`,
          };
        }

        if (profissional[0].ZA2_PROFIS === "000017") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT VENDEDOR,SUM(FATURAMENTO_LIQUIDO) FATURAMENTO FROM DB_V_RECEITAS_BI WHERE
                                                        LINHA='CIRURGICA'
                                                        AND ANOMES IN (${anomes})
                                                        AND CHAVE_VENDEDOR IN (${equipe.vendedores})
                                                        AND LEFT(DESC_SUBGRUPO_PRODUTO,4) IN ('0007','0023')
                                                        AND APAC<>'S'
                                                        GROUP BY VENDEDOR`,
          };
        }

        if (profissional[0].ZA2_PROFIS === "000013") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT VENDEDOR,SUM(FATURAMENTO_LIQUIDO) FATURAMENTO FROM DB_V_RECEITAS_BI WHERE
                                                                              CHAVE_CANAL='000001'
                                                                              AND GRUPO_PRODUTO = '1001'
                                                                              AND CHAVE_VENDEDOR IN (${equipe.vendedores})
                                                                              AND PLANO_SAUDE<>'1'
                                                                              AND APAC<>'S'
                                                                              AND ANOMES IN (${anomes})
                                                                              AND RELATORIO_BI<>'2' GROUP BY VENDEDOR`,
          };
        }

        if (equipe.loja === "CRM") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT 'LEADS DIGITAIS' VENDEDOR,SUM(FATURAMENTO_LIQUIDO) FATURAMENTO FROM DB_V_RECEITAS_BI WHERE
                                                                              LEFT(CANAL,6)='000002'
                                                                              AND ANOMES IN (${anomes}) `,
          };
        }

        if (equipe.loja === "SVP") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;

          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO,VENDEDOR FROM DB_V_RECEITAS_BI WHERE
                                                                              GRUPO_PRODUTO IN ('2001','1004')
                                                                              AND ANOMES IN (${anomes})
                                                                              AND LINHA IN ('AUDITIVA','SERVICOS')
                                                                              AND LEFT(DESC_SUBGRUPO_PRODUTO,4) IN ('0004','0005')


                                                                              GROUP BY VENDEDOR `,
          };
        }
        if (equipe.loja === "0301") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO,VENDEDOR FROM DB_V_RECEITAS_BI WHERE
                                                                              GRUPO_PRODUTO='2001'
                                                                              AND ANOMES IN (${anomes})
                                                                              AND LEFT(FILIAL,4)='0301'
                                                                              AND LEFT(DESC_SUBGRUPO_PRODUTO,4)='0004  '
                                                                              AND LEFT(ARMAZEM,2)='AT'
                                                                              AND TEC='${profissional[0].ZA2_SEGVEN}'
                                                                              GROUP BY VENDEDOR `,
          };
        }

        if (
          profissional[0].ZA2_PROFIS === "000005" &&
          equipesCirur.includes(equipe.loja)
        ) {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO,VENDEDOR FROM DB_V_RECEITAS_BI WHERE
                                                                              LINHA='CIRURGICA'
                                                                              AND ANOMES IN (${anomes})
                                                                              AND CHAVE_VENDEDOR IN (${equipe.vendedores})
                                                                              AND LEFT(DESC_SUBGRUPO_PRODUTO,4) IN ('0007','0023')
                                                                              AND APAC<>'S'
                                                                              GROUP BY VENDEDOR `,
          };
        }

        if (profissional[0].ZA2_PROFIS === "000013") {
          urlNode = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;
          dadosUrl = {
            query: `SELECT SUM(FATURAMENTO_LIQUIDO) FATURAMENTO,VENDEDOR FROM DB_V_RECEITAS_BI WHERE
                                                                              CHAVE_CANAL='000001'
                                                                              AND GRUPO_PRODUTO = '1001'
                                                                              AND CHAVE_VENDEDOR IN (${equipe.vendedores})
                                                                              AND PLANO_SAUDE<>'1'
                                                                              AND APAC<>'S'
                                                                              AND ANOMES IN (${anomes})
                                                                              AND RELATORIO_BI<>'2' GROUP BY VENDEDOR `,
          };
        }

        const faturamentoVendedores = await axios
          .post(urlNode, dadosUrl)
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.commit("SetUser", null);
              return;
            }
          });

        //Filtrando somente as vendas do painel deste prospector
        // if (profissional[0].ZA2_PROFIS === "000013") {
        //   const urlPainel = `${process.env.VUE_APP_BASE_API_URL}/painelProspector`;

        //   const metricas = await axios.post(urlPainel, {
        //     ano: this.anoEscolhido,
        //     trimestre: this.trimestreEscolhido,
        //     vendedor: this.vendedor,
        //   });

        //   if (!metricas || metricas.status !== 200) {
        //     console.log("erro");
        //     return;
        //   }

        //   let fatPainel = [];

        //   metricas.data.map(m => {
        //     faturamentoVendedores.data
        //       .filter(e => e.INDICADOR.substring(0, 6) === m.ZXH_MEDICO)
        //       .map(e =>
        //         fatPainel.push({
        //           FATURAMENTO: e.FATURAMENTO,
        //           VENDEDOR: e.VENDEDOR,
        //         }),
        //       );
        //   });

        //   faturamentoVendedores.data = fatPainel;
        // }

        const totalFaturamentoVendedores = faturamentoVendedores.data.reduce(
          (total, item) => total + item.FATURAMENTO,
          0,
        );

        this.totalGeralEquipes += totalFaturamentoVendedores;

        detalhamentoFatVendedores = faturamentoVendedores.data.map(e => {
          return {
            VENDEDOR: e.VENDEDOR,
            FATURAMENTO: e.FATURAMENTO.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
          };
        });

        const dadosComissao = dadosVendedor.filter(
          e => e.ZA8_IDEQP === equipe.idEquipe,
        );

        let calculoDados = [];
        let metaAteEquipe = 0;

        this.labelDivisaoEquipe = null;

        if (dadosComissao[0].ZXG_QTDP < 1) {
          dadosComissao[0].ZXG_QTDP = 1;
        }

        equipe.meta = Number(
          (equipe.meta / dadosComissao[0].ZXG_QTDP).toFixed(2),
        );

        dadosComissao.forEach(e => {
          let valorInicial = 0;
          let valorFinal = 0;

          valorInicial = Number(
            ((equipe.meta * e.ZA3_INDINI) / 100).toFixed(2),
          );

          if (metaAteEquipe <= 0) {
            metaAteEquipe = (valorInicial - 0.01).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          }

          const proximoRange = e.ZA3_INDFIM + 0.01;
          valorFinal = Number(
            ((equipe.meta * proximoRange) / 100 - 0.01).toFixed(2),
          );

          let valorMaxPorc = (totalFaturamentoVendedores * 100) / equipe.meta;

          let tagMax = "";
          if (valorMaxPorc > e.ZA2_LIMITE) {
            tagMax = "Excedeu o limite de 120%";
          }

          if (
            totalFaturamentoVendedores <= valorFinal &&
            totalFaturamentoVendedores >= valorInicial
          ) {
            profissional = this.dadosVendedoresGeral.filter(
              e => e.ZA2_CODVEN === this.vendedor && e.ZA2_TIPO === "E",
            );

            let valorBase = Number(
              ((equipe.meta * e.ZA3_PBASE) / 100).toFixed(2),
            );

            let valorPremio = Number(
              ((valorBase * e.ZA3_PERC) / 100).toFixed(2),
            );

            valorPremio = 50 - (valorPremio % 50) + valorPremio;

            valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

            if (e.ZXG_PESO < 100) {
              valorPremio = 50 - (valorPremio % 50) + valorPremio;
            }

            // valorPremio = Number(((valorPremio * e.ZA2_PESO) / 100).toFixed(2));

            this.totalGeralPremio += valorPremio;
            this.totalPremioEquipe += valorPremio;

            calculoDados.push({
              vl_vendido: totalFaturamentoVendedores.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              vl_vendidoCurr: totalFaturamentoVendedores,
              fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: valorPremio.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premioCurr: valorPremio,
            });
          } else {
            calculoDados.push({
              vl_vendido: totalFaturamentoVendedores.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              vl_vendidoCurr: totalFaturamentoVendedores,
              fx_inicial: `${e.ZA3_INDINI}%`,
              fx_final: `${e.ZA3_INDFIM}%`,
              porcentagem_aplicada: `${e.ZA3_PERC}%`,
              vl_inicial: valorInicial.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_final: valorFinal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRl",
              }),
              vl_premio: "-",
              vl_premioCurr: 0,
            });
          }
        });

        this.dadosDoCalculoEquipe.push({
          nome: equipe.nome,
          meta: equipe.meta.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          detalhamento: detalhamentoFatVendedores,
          totalFaturamento: totalFaturamentoVendedores,
          metaAte: metaAteEquipe,
          calculo: calculoDados,
          loja: equipe.loja,
        });

        this.andamento += identador;
      }

      this.calculoDadosComissaoEquipe = [];

      this.$toast.success("Prêmio de equipe gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async buscaVendedores() {
      this.vendedores = [];
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `DISTINCT ZA2_CODVEN,A3_NOME,A3_ZTIPO,Z0_DESC,ZMA_DESCRI`,
        INNER: ` INNER JOIN SA3010 SA3 WITH(NOLOCK) ON (A3_COD=ZA2_CODVEN AND SA3.D_E_L_E_T_=' ')
                 INNER JOIN SZ0010 SZ0 WITH(NOLOCK) ON (Z0_COD=A3_ZTIPO AND SZ0.D_E_L_E_T_=' ')
                 INNER JOIN ZMA010 ZMA WITH(NOLOCK) ON (ZMA_IDAREA=A3_ZZAREA AND ZMA.D_E_L_E_T_=' ')`,
        WHERE: ``,
        TABELA: `ZA2`,
      };
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          this.vendedores = res.data.map(e => {
            return {
              text: `${e.ZA2_CODVEN}-${e.A3_NOME}`,
              value: e,
            };
          });
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });
    },
    async buscaPessoas() {
      this.dadosVendedoresGeral = [];
      this.vendedores = [];
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `A3_EMAIL,A3_NOME,ZA2_PESO,ZA2_FONOS,ZA2_LOJA,ZA2_CODVEN,ZA2_ID,ZA2_TIPO,ZA2_META1,ZA2_META2,ZA2_META3,ZA2_META4,ZA2_QTDP,ZA2_ANO,ZA2_PROFIS,ZA9_DESCRI,ZA2_SEGVEN`,
        INNER: ` INNER JOIN SA3010 SA3 WITH(NOLOCK) ON
                                SA3.D_E_L_E_T_=' '
                                AND A3_COD=ZA2_CODVEN
                                --AND A3_MSBLQL='2'

                  LEFT JOIN SZ0010 SZ0 WITH(NOLOCK) ON
                                SZ0.D_E_L_E_T_=' '
                                AND Z0_COD=A3_ZTIPO

                  LEFT JOIN ZA9010 ZA9 WITH(NOLOCK) ON
                                ZA9.D_E_L_E_T_=' '
                                AND ZA9_COD=ZA2_PROFIS `,
        WHERE: `ZA2_ANO='${this.anoEscolhido}' ORDER BY SA3.A3_NOME `,
        TABELA: `ZA2`,
      };
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      await axios.post(url, dados).then(res => {
        this.dadosVendedoresGeral = res.data;
        const arrayUnique = [
          ...new Set(res.data.map(e => `${e.ZA2_CODVEN}-${e.A3_NOME}`)),
        ];

        this.vendedores = arrayUnique.map(e => {
          return {
            text: e,
            value: e.substring(0, 6),
          };
        });
      });
    },
    async handleGerarPdf() {
      this.snackbar_error = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/premio/pdf`;
      let tableEquipe = [];
      let plus = false;

      if (this.countEquipes <= 1 && this.numberofEquipes <= 1) {
        tableEquipe = this.premioAdmin
          ? this.dadosDoCalculoEquipe.push({
              meta: 0,
              metaAte: 0,
              detalhamento: [],
            })
          : this.dadosDoCalculoEquipe[0].calculo;
      } else {
        plus = true;
        this.dadosDoCalculoEquipe.map(
          e =>
            (e.premio = e.calculo.reduce(
              (total, item) => total + item.vl_premioCurr,
              0,
            )),
        );

        tableEquipe = this.dadosDoCalculoEquipe.map(e => {
          return {
            nome: e.nome.trim(),
            metaAte: e.metaAte,
            meta: e.meta,
            totalFaturamento: e.totalFaturamento,
            premio: e.premio,
          };
        });
      }

      const dados = {
        tabelas: [
          {
            title: "Prêmio Individual",
            meta: this.metaIndividual,
            metaAte: this.metaAteIndividual,
            tabela: this.calculoDadosComissao,
            equipe: false,
          },
          {
            title: "Prêmio Equipe",
            meta: this.dadosDoCalculoEquipe[0].meta,
            metaAte: this.dadosDoCalculoEquipe[0].metaAte,
            tabela: tableEquipe,
            equipe: !this.premioAdmin
              ? tableEquipe[0]?.nome
                ? true
                : false
              : false,
          },
          {
            title: "Prêmio Opgrade",
            meta: this.metaIndividualOpGrade,
            metaAte: this.metaAteIndividualOpGrade,
            tabela: this.calculoDadosComissaoOpGrade,
            equipe: false,
          },
          {
            title: "Prêmio indicação médica",
            meta: this.metaIndividualIndicacao,
            metaAte: this.metaAteIndividualIndicacao,
            tabela: this.calculoDadosComissaoIndicacao,
            equipe: false,
          },
          {
            title: "Prêmio Visitação",
            meta: this.metaIndividualVisitacao,
            metaAte: this.metaAteIndividualVisitacao,
            tabela: this.calculoDadosComissaoVisitacao,
            equipe: false,
          },
          {
            title: "Prêmio Agendamento Leads",
            meta: this.metaIndividualAgendamento,
            metaAte: this.metaAteIndividualAgendamento,
            tabela: this.calculoDadosComissaoAgendamento,
            equipe: false,
          },
          {
            title: "Prêmio Comparecimento Agenda Leads",
            meta: this.metaAteIndividualComparecimento,
            metaAte: this.metaAteIndividualComparecimento,
            tabela: this.calculoDadosComissaoComparecimento,
            equipe: false,
          },
        ],
        totalPremioIndividual: this.totalPremioIndividual.toLocaleString(
          "pt-BR",
          { style: "currency", currency: "BRL" },
        ),
        totalPremioOpGrade: this.totalPremioOpGrade.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalPremioEquipe: this.totalPremioEquipe.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalPremioGeral: this.totalPremioGeral.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalRefinamento: this.totalRefinamento,
        obsRefinamento: this.obsRefinamento?.trim(),
        plus,
        nomeVendedor: `${this.labelBanner1}`,
      };

      try {
        const res = await axios.post(url, dados, { responseType: "blob" });
        const browserTab = window.open(URL.createObjectURL(res.data), "_blank");

        if (browserTab) {
          browserTab.focus();
        } else {
          this.error = "pop-up do arquivo PDF bloqueada pelo navegador";
          this.snackbar_error = true;
        }
      } catch (error) {
        this.error = error;
      }
    },
    async buscaAnos() {
      const anosSub = sub(new Date(), { years: 2 });

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anosSub, { years: cont }).getFullYear() <=
          new Date().getFullYear()
        ) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      }

      this.anos = this.anos.reverse();
      this.loadingAnos = false;
    },
    async chamaDialogEmail() {
      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor,
      );

      this.emailEnvio = profissional[0].A3_EMAIL.trim();
      this.dialogEmail = true;
    },
    async handleEnviaEmail() {
      if (!this.emailEnvio) return;

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor,
      );

      this.snackbar_error = false;
      let tableEquipe = [];
      let plus = false;
      if (this.countEquipes <= 1 && this.numberofEquipes <= 1) {
        tableEquipe = this.premioAdmin
          ? this.dadosDoCalculoEquipe.push({ meta: 0, metaAte: 0 })
          : this.dadosDoCalculoEquipe[0].calculo;
      } else {
        plus = true;
        this.dadosDoCalculoEquipe.map(
          e =>
            (e.premio = e.calculo.reduce(
              (total, item) => total + item.vl_premioCurr,
              0,
            )),
        );

        tableEquipe = this.dadosDoCalculoEquipe.map(e => {
          return {
            nome: e.nome.trim(),
            metaAte: e.metaAte,
            meta: e.meta,
            totalFaturamento: e.totalFaturamento,
            premio: e.premio,
          };
        });
      }

      const dadosPdf = {
        tabelas: [
          {
            title: "Prêmio Individual",
            meta: this.metaIndividual,
            metaAte: this.metaAteIndividual,
            tabela: this.calculoDadosComissao,
            equipe: false,
          },
          {
            title: "Prêmio Equipe",
            meta: this.dadosDoCalculoEquipe[0].meta,
            metaAte: this.dadosDoCalculoEquipe[0].metaAte,
            tabela: tableEquipe,
            equipe: !this.premioAdmin
              ? tableEquipe[0]?.nome
                ? true
                : false
              : false,
          },
          {
            title: "Prêmio Opgrade",
            meta: this.metaIndividualOpGrade,
            metaAte: this.metaAteIndividualOpGrade,
            tabela: this.calculoDadosComissaoOpGrade,
            equipe: false,
          },
          {
            title: "Prêmio indicação médica",
            meta: this.metaIndividualIndicacao,
            metaAte: this.metaAteIndividualIndicacao,
            tabela: this.calculoDadosComissaoIndicacao,
            equipe: false,
          },
          {
            title: "Prêmio Visitação",
            meta: this.metaIndividualVisitacao,
            metaAte: this.metaAteIndividualVisitacao,
            tabela: this.calculoDadosComissaoVisitacao,
            equipe: false,
          },
          {
            title: "Prêmio Agendamento Leads",
            meta: this.metaIndividualAgendamento,
            metaAte: this.metaAteIndividualAgendamento,
            tabela: this.calculoDadosComissaoAgendamento,
            equipe: false,
          },
          {
            title: "Prêmio Comparecimento Agenda Leads",
            meta: this.metaAteIndividualComparecimento,
            metaAte: this.metaAteIndividualComparecimento,
            tabela: this.calculoDadosComissaoComparecimento,
            equipe: false,
          },
        ],
        totalPremioIndividual: this.totalPremioIndividual.toLocaleString(
          "pt-BR",
          { style: "currency", currency: "BRL" },
        ),
        totalPremioOpGrade: this.totalPremioOpGrade.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalPremioEquipe: this.totalPremioEquipe.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalPremioGeral: this.totalPremioGeral.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        totalRefinamento: this.totalRefinamento,
        obsRefinamento: this.obsRefinamento?.trim(),
        plus,
        nomeVendedor: `${this.labelBanner1}`,
      };

      const dados = {
        nome: profissional[0].A3_NOME.trim(),
        email: this.emailEnvio,
        titulo: "Premiação trimestral",
        dadosPdf,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/enviaPremio`;

      this.aguardarEnvio = true;

      await axios.post(url, dados).then(() => {
        this.dialogEmail = false;
        this.emailEnvio = null;
        this.aguardarEnvio = false;
        this.$toast.success("E-mail enviado com sucesso!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    },
    async premioAdm(item) {
      console.log(item);
      this.premioAdmin = true;
      this.itemsDetalhamento = [];
      this.showSkelekton = true;
      this.showDados = false;
      this.numberLines = 0;
      this.valorTotalDetalhamento = null;
      let url = `${process.env.VUE_APP_BASE_API_URL}/metricasAdm`;

      this.titlePremioCalc = "Buscando faturamento...";

      const metricas = await axios.post(url, {
        ano: this.anoEscolhido,
        trimestre: this.trimestre,
      });

      if (!metricas || metricas.status !== 200) {
        console.log("erro");
        return;
      }

      const profissional = this.dadosVendedoresGeral.filter(
        e => e.ZA2_CODVEN === this.vendedor && e.ZA2_TIPO === "A",
      );

      console.log(profissional);

      this.titlePremioCalc = "Buscando dados do prêmio...";

      url = `${process.env.VUE_APP_BASE_API_URL}/dadosPremio`;

      const dadosPremio = await axios.post(url, {
        trimestre: this.trimestreEscolhido,
        ano: this.anoEscolhido,
        vendedor: this.vendedor,
        tipo: "A",
      });

      let dadosComissao = dadosPremio.data;
      this.calculoDadosComissao = [];
      this.metaAteIndividual = null;
      this.labelDivisaoIndividual = null;

      let calculoDados = [];

      dadosComissao.forEach(e => {
        let valorInicial = Number(
          ((metricas.data.metaTotal * e.ZA3_INDINI) / 100).toFixed(2),
        );
        // dinero({amount: metricas.data.metaTotal * 100, currency: "BRL"}).percentage(e.ZA3_INDINI).setLocale("pt-BR").toFormat();

        let valorFinal = Number(
          (
            (metricas.data.metaTotal * (e.ZA3_INDFIM + 0.01)) / 100 -
            0.01
          ).toFixed(2),
        );

        if (this.metaAteIndividual <= 0) {
          const valor = Number(
            (valorInicial - 0.01).toFixed(2).replace(".", ""),
          );
          this.metaAteIndividual = dinero({ amount: valor, currency: "BRL" })
            .setLocale("pt-BR")
            .toFormat();
        }

        let tagMax = "";
        if (Number(metricas.data.range.toFixed(2)) > e.ZA2_LIMITE) {
          tagMax = "Excedeu o limite de 120%";
        }

        if (
          metricas.data.faturamentoTotal <= valorFinal &&
          metricas.data.faturamentoTotal >= valorInicial
        ) {
          let valorBase = Number(
            ((e.ZA2_SALARI * e.ZXG_PESO) / 100).toFixed(2),
          );
          let valorPremio = Number(((valorBase * e.ZA3_PERC) / 100).toFixed(2));

          valorPremio = 50 - (valorPremio % 50) + valorPremio;

          valorPremio = Number(((valorPremio * e.ZXG_PESO) / 100).toFixed(2));

          if (e.ZXG_PESO < 100) {
            valorPremio = 50 - (valorPremio % 50) + valorPremio;
          }

          this.totalGeralPremio += valorPremio;
          this.totalPremioIndividual += valorPremio;

          let valorVendido = Number(
            metricas.data.faturamentoTotal.toFixed(2).replace(".", ""),
          );
          let valorIni = Number(valorInicial.toFixed(2).replace(".", ""));
          let valorFim = Number(valorFinal.toFixed(2).replace(".", ""));
          let valorPre = Number(valorPremio.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: dinero({ amount: valorVendido, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
            fx_inicial: `${e.ZA3_INDINI}% ${tagMax}`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: dinero({ amount: valorIni, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
            vl_final: dinero({ amount: valorFim, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
            vl_premio: dinero({ amount: valorPre, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
          });
        } else {
          let valorVendido = Number(
            metricas.data.faturamentoTotal.toFixed(2).replace(".", ""),
          );
          let valorIni = Number(valorInicial.toFixed(2).replace(".", ""));
          let valorFim = Number(valorFinal.toFixed(2).replace(".", ""));

          calculoDados.push({
            vl_vendido: dinero({ amount: valorVendido, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
            fx_inicial: `${e.ZA3_INDINI}%`,
            fx_final: `${e.ZA3_INDFIM}%`,
            porcentagem_aplicada: `${e.ZA3_PERC}%`,
            vl_inicial: dinero({ amount: valorIni, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
            vl_final: dinero({ amount: valorFim, currency: "BRL" })
              .setLocale("pt-BR")
              .toFormat(),
            vl_premio: "-",
          });
        }
      });

      const metaindividual = Number(
        metricas.data.metaTotal.toFixed(2).replace(".", ""),
      );

      this.metaIndividual = dinero({ amount: metaindividual, currency: "BRL" })
        .setLocale("pt-BR")
        .toFormat();

      this.calculoDadosComissao = calculoDados;

      this.$toast.success("Prêmio individual gerado com sucesso!", {
        position: "bottom-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.buscaAnos();

    // this.buscaVendedores();
  },
};
</script>

<style scoped></style>
